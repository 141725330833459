import React, { useState } from "react";
import ClientTable from "../../components/ClientTable";
import Dash from "../../components/Dash";
import Layout2 from "../../components/Layout/Layout2";
import Loading from "../../components/Loading";
import Search from "../../components/Search";
import {
  clientDatabyid,
  getAccessCustomer,
  getAllCustomer,
  getProductData,
  getallpendingorder,
  getallpendingorderbyid,
} from "../../utils/api";
import useStore from "../../utils/store";

const Home = () => {
  const [access, setAccess] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState([]);
  const [data, setData] = useState(null);

  const [products, setProducts] = useState(null);
  const getProductsHandler = async () => {
    setLoading(true);
    const [success, data] = await getProductData();
    if (success) {
      setProducts(data);
    }
    setLoading(false);
  };
  const user = useStore((state) => state.user);
  const getAllCustomerHandler = async () => {
    setLoading(true);
    const [success, data] = await getAccessCustomer(user.token);
    if (success) {
      setAccess(
        data.map((item) => ({
          ...item,
          name: item?.userName,
        }))
      );
    }
    setLoading(false);
  };

  const getAllCustomersData = async () => {
    setLoading(true);
    const [success, data] = await getAllCustomer(user.token);
    if (success) {
      setData({
        name: "ALL",
        _id: "ALL",
        ...data,
      });
    }
    setLoading(false);
  };

  const getallpendingorderbyidHandler = async (id) => {
    setLoading(true);
    const [success, data] = await getallpendingorderbyid(id);
    if (success) {
      setPending(data);
    }
    setLoading(false);
  };

  const getAllPendingOrder = async () => {
    setLoading(true);
    const [success, data] = await getallpendingorder(user.token);
    if (success) {
      setPending(data);
    }
    setLoading(false);
  };

  const getCustomersByIdHandler = async (id, name) => {
    setLoading(true);
    const [success, data] = await clientDatabyid(id);
    if (success) {
      setData({
        ...data,
        name,
        cur: id,
        active: name,
      });
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getAllCustomerHandler();
    getAllCustomersData();
    getProductsHandler();
  }, []);
  return (
    <Layout2
      products={products}
      customers={access}
      onClick={() => {
        data?.name === "ALL"
          ? getAllPendingOrder()
          : getallpendingorderbyidHandler(data?.cur);
      }}
    >
      <div className="flex gap-6 ">
        {loading && <Loading />}
        <Search
          data={[
            {
              name: "ALL",
              active: "ALL",
            },
            ...access,
          ]}
          active={data?.cur}
          item={(item) => {
            setPending(null);
            if (item.name === "ALL") {
              getAllCustomersData();
              return;
            }
            getCustomersByIdHandler(item._id, item?.name);
          }}
        />

        {data && (
          <div className="flex flex-col gap-6 flex-1 md:w-9/12">
            <div className="flex gap-6 justify-between">
              <h2 className="text-xl px-2 font-bold bg-secondary   border border-black w-fit">
                {data?.name}
              </h2>
              <div className="bg-secondary border border-black w-fit px-2 font-semibold">
                Wagon : {data?.name === "ALL" ? data?.wagongs : data?.wagon}
              </div>
              <div className="bg-secondary border border-black w-fit px-2 font-semibold">
                Tag :{" "}
                {data?.name === "ALL"
                  ? access.reduce((a, b) => a + b?.wagon, 0) * 580
                  : access.find((item) => item._id === data?.cur)?.wagon * 580}
              </div>
              <div className="bg-secondary border border-black w-fit px-2 font-semibold">
                Complete :{data?.orderTagAmount}
              </div>
              <div className="bg-secondary border border-black w-fit px-2 font-semibold">
                Balance :
                {parseInt(
                  data?.name === "ALL"
                    ? access.reduce((a, b) => a + b?.wagon, 0) * 580
                    : access.find((item) => item._id === data?.cur)?.wagon * 580
                ) - parseInt(data?.orderTagAmount)}
              </div>
            </div>
            <div className="flex gap-6">
              <Dash title={"Sale"} value={data?.totalSales.toFixed(2)} />
              <Dash
                title={"Payment Recived"}
                value={data?.totalPaymentRecived.toFixed(2)}
              />
              <Dash title={"Balance"} value={data?.totalBalance.toFixed(2)} />
            </div>{" "}
            {pending && (
              <p className="text-xl px-2 font-bold bg-secondary   border border-black w-fit">
                Pending : {pending?.records?.length}
              </p>
            )}
            <ClientTable
              id={data?.cur}
              all={data?.name === "ALL"}
              customer
              data={pending ? pending?.records : [...data?.records]?.reverse()}
              isCustomerSection={true}
              columns={[
                {
                  title: "Date",
                  dataIndex: "updatedAt",
                },
                {
                  title: "Customer",
                },
                {
                  title: "description",
                  dataIndex: "description",
                },
                {
                  title: "Items",
                },
                {
                  title: "Qnt.",
                  dataIndex: "qnt",
                },
                {
                  title: "Rate",
                  dataIndex: "rate",
                },
                {
                  title: "GST",
                  dataIndex: "gst",
                },
                {
                  title: "Total",
                },
              ]}
            />
          </div>
        )}
      </div>
    </Layout2>
  );
};

export default Home;
