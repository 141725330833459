import React, { useState } from "react";

import Layout from "../../components/Layout/Layout";
import Search from "../../components/Search";

import {
  deleteCustomer,
  getAllCustomerGroups,
  getAllCustomers,
  getWagon,
  resetcustomer,
  updateCustomer,
} from "../../utils/api";

import { toast } from "react-toastify";
import Loading from "../../components/Loading";
import Table from "../../components/Table";
import ClientModal from "../../components/modals/ClientModal";
import Confirm from "../../components/modals/Confirm";

const ADDCustomer = () => {
  const [open, setOpen] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [groups, setGroups] = useState([]);
  const getAllCustomerHandler = async () => {
    setLoading(true);
    const [success, data] = await getAllCustomers();
    if (success) {
      setCustomers(
        data.map((item) => ({
          ...item,
          name: `${item.userName} ${
            item?.group?.name !== undefined ? `(${item?.group?.name})` : ""
          }`,
        }))
      );
    }
    setLoading(false);
  };

  const getAllCustomerGroupsHandler = async () => {
    setLoading(true);
    const [success, data] = await getAllCustomerGroups();
    if (success) {
      setGroups(data);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getAllCustomerHandler();
    getAllCustomerGroupsHandler();
  }, []);

  return (
    <Layout
      text="Add New Customer"
      onClick={() => {
        setOpen(true);
      }}
    >
      {loading && <Loading />}
      <div className="flex gap-3 flex-col md:flex-row">
        <Search
          data={customers.filter((item) => item?.type === "Customer")}
          item={(item) => {
            setSelected(item);
          }}
          active={selected?._id}
        />
        {selected && (
          <EditClient
            item={selected}
            reload={getAllCustomerHandler}
            setItem={setSelected}
            customers={customers}
            groups={groups}
          />
        )}
      </div>

      <ClientModal
        open={open}
        setOpen={setOpen}
        reload={getAllCustomerHandler}
        customers={customers}
        groups={groups}
        customer
      />
    </Layout>
  );
};

export default ADDCustomer;

const EditClient = ({ item, reload, setItem, customers, groups }) => {
  const [edit, setEdit] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [wagon, setWagon] = React.useState(0);

  const getAllWagon = async () => {
    const [success, data] = await getWagon(item?._id);
    if (success) {
      setWagon(data?.wagon);
    }
  };

  React.useEffect(() => {
    getAllWagon();
  }, [item?._id]);

  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col gap-3">
        <Table
          data={[item]}
          columns={[
            {
              title: "Username",
              dataIndex: "userName",
            },
            {
              title: "Phone Number",
              dataIndex: "phoneNumber",
            },
            {
              title: "Image",
              dataIndex: "image",
              render: (item) => (
                <a href={item.image} target="_blank" rel="noreferrer">
                  {item.image ? "View" : ""}
                </a>
              ),
            },
            {
              title: "Action",
              render: (item) => {
                return (
                  <div className="flex gap-2">
                    <button
                      onClick={() => {
                        setItem({
                          ...item,
                          wagon: wagon,
                        });
                        setEdit(true);
                      }}
                      className="border mr-2 border-black px-4 py-1 bg-yellow-400 text-xl "
                    >
                      Edit
                    </button>
                    <button
                      onClick={async () => {
                        const [success, data] = await deleteCustomer(item?._id);
                        if (success) {
                          setItem(null);
                          reload();
                          toast.success("Deleted");
                        }
                      }}
                      className="border mr-2 border-black px-4 py-1 bg-red-500 text-xl "
                    >
                      Delete
                    </button>{" "}
                    <button
                      onClick={() => {
                        setOpen2(true);
                      }}
                      className="border mr-2 border-black px-4 py-1 bg-secondary text-xl "
                    >
                      Reset
                    </button>
                    {open2 && (
                      <Confirm
                        open={open2}
                        setOpen={setOpen2}
                        onClick={async () => {
                          const [success, data] = await resetcustomer(
                            item?._id
                          );
                          if (success) {
                            setOpen2(false);
                            setItem(null);
                            reload();
                            toast.success("Reset Complete");
                          }
                        }}
                      />
                    )}
                  </div>
                );
              },
            },
          ]}
        />

        <div className="border border-black bg-green-600 px-2 py-1 w-fit">
          Clients
        </div>
        <Table
          data={item?.access}
          columns={[
            {
              title: "Username",
              dataIndex: "userName",
            },
            {
              title: "Phone Number",
              dataIndex: "phoneNumber",
            },
            {
              title: "Action",
              render: (item2) => {
                return (
                  <div className="flex gap-2">
                    <button
                      className="bg-red-500 px-2 py-1  border border-black"
                      onClick={async () => {
                        const [success, data] = await updateCustomer(
                          item?._id,
                          {
                            ...item,
                            access: item?.access.filter(
                              (item3) => item3?._id !== item2?._id
                            ),
                          }
                        );
                        if (success) {
                          setItem({
                            ...item,
                            access: item?.access.filter(
                              (item3) => item3?._id !== item2?._id
                            ),
                          });
                          reload();
                          toast.success("Deleted");
                        }
                      }}
                    >
                      Delete
                    </button>
                  </div>
                );
              },
            },
          ]}
        />
      </div>

      <ClientModal
        open={edit}
        setOpen={setEdit}
        reload={reload}
        update={item}
        customers={customers}
        setUpdate={setItem}
        customer
        groups={groups}
      />
    </div>
  );
};
