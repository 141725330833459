import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IoIosClose } from "react-icons/io";
import { toast } from "react-toastify";
import Dash from "../../components/Dash";
import TotalCustomers from "../../components/dashboard/TotalCustomers";
import Layout from "../../components/Layout/Layout";
import Loading from "../../components/Loading";
import CustomModal, { ModalInput } from "../../components/Modal";
import CustomSelect from "../../components/Select";
import Table from "../../components/Table";
import {
  admindashboardPurchaseOrders,
  completeOrder,
  dashboardCompletedOrders,
  dashboardProfit,
  deleteOrder,
  getAdminDashboard,
  getAllCustomers,
  getCustomerDashData,
  getCustomerGroup,
  getDashboardCount,
  getDashboardProfit,
  getDashboardPurchase,
  getDashboardSalary,
  getDashboardSale,
  getMarketDashboardHandler,
  getProductData,
  updateOrder,
} from "../../utils/api";
import { formatDate } from "../../utils/formatData";
import useStore from "../../utils/store";

const Dashboard = () => {
  const [dash, setDash] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [open, setOpen] = useState(false);
  const startDate = useStore((state) => state.startDate);
  const endDate = useStore((state) => state.endDate);
  const [dashboardCount, setDashCount] = useState(null);
  const [totalCustomers, setTotalCustomers] = useState(null);

  const [customerData, setCustomerData] = useState([]);
  const [customers, setCustomers] = React.useState([]);
  const [dashboardData, setDashboardData] = React.useState([]);
  const getCustomers = async () => {
    setLoading(true);
    const [success, data] = await getAllCustomers(startDate, endDate);
    if (success) {
      setCustomers(data);
    }
    setLoading(false);
  };

  const getDashboardCountHandler = async (type) => {
    setLoading(true);
    const [success, data] = await getDashboardCount(type, startDate, endDate);
    if (success) {
      setDashCount({
        dataType: type,
        ...data,
      });
      setDashboardData(data.data);
      setOpen(false);
      setTotalCustomers(null);
    }
    setLoading(false);
  };
  console.log(dashboardCount, "dashboardCount");

  const getCustomerGroupHandler = async () => {
    setLoading(true);
    const [success, data] = await getCustomerGroup(startDate, endDate);
    if (success) {
      setTotalCustomers(data);
      setOpen(false);
      setDashboardData([]);
      setDashCount(null);
    }
    setLoading(false);
  };
  const [newOrder, setNewOrder] = React.useState({});
  const [update, setUpdate] = React.useState(false);
  const [products, setProducts] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [purchaseOrders, setPurchaseOrders] = useState([]);

  const user = useStore((state) => state.user);
  const getAdminDashboardHandler = async () => {
    setLoading(true);
    const [success, data] = await getAdminDashboard(startDate, endDate);
    if (success) {
      setDash(data);
    }
    setLoading(false);
  };
  const getProductsHandler = async () => {
    setLoading(true);
    const [success, data] = await getProductData(startDate, endDate);
    if (success) {
      setProducts(data);
    }
    setLoading(false);
  };

  const getAllOrdersHandler = async () => {
    setLoading(true);
    const [success, data] = await getMarketDashboardHandler(startDate, endDate);
    if (success) {
      setPendingOrders(data ?? []);
    }
    setLoading(false);
  };

  const getCustomerDataById = async (id) => {
    setLoading(true);
    const [success, data] = await getCustomerDashData(id, startDate, endDate);
    if (success) {
      setCustomerData(data.map((item) => ({ ...item, id: id, _id: item.id })));
    }
    setLoading(false);
  };

  const getAllCompletedOrdersHandler = async () => {
    setLoading(true);
    const [success, data] = await dashboardCompletedOrders(startDate, endDate);
    if (success) {
      setCompletedOrders(data);
    }
    setLoading(false);
  };

  const getAllPurchaseOrdersHandler = async () => {
    setLoading(true);
    const [success, data] = await admindashboardPurchaseOrders(
      startDate,
      endDate
    );
    if (success) {
      setPurchaseOrders(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAdminDashboardHandler();
    getAllOrdersHandler();
    getProductsHandler();
    getCustomers();
    getAllCompletedOrdersHandler();
    getAllPurchaseOrdersHandler();
  }, [startDate, endDate]);

  return (
    <Layout
      reload={() => {
        getAdminDashboardHandler();
        getAllOrdersHandler();
        getProductsHandler();
        getCustomers();
      }}
    >
      {loading && <Loading />}
      <div className="flex gap-3 flex-col md:flex-row flex-wrap">
        <button
          onClick={() => {
            setDashboardData([]);
            setDashCount(null);
            setOpen(!open);
            setTotalCustomers(null);
          }}
          className="flex-1"
        >
          <Dash
            title={"Pending Orders"}
            value={open && dash?.totalPendingOrders}
          />
        </button>
        <button onClick={getCustomerGroupHandler} className="flex-1">
          <Dash
            title={" Customers"}
            value={totalCustomers && dash?.totalCustomers}
          />
        </button>
        <button
          onClick={() => getDashboardCountHandler("Profit")}
          className="flex-1"
        >
          <Dash
            title={" Profit"}
            value={
              dashboardCount?.dataType === "Profit" &&
              dash?.totalProfit?.toFixed(2)
            }
          />
        </button>
        <button
          onClick={() => getDashboardCountHandler("Purchase")}
          className="flex-1"
        >
          <Dash
            title={" Purchase"}
            value={
              dashboardCount?.dataType === "Purchase" &&
              dash?.totalPurchase?.toFixed(2)
            }
          />
        </button>
        <button
          onClick={() => getDashboardCountHandler("Sale")}
          className="flex-1"
        >
          <Dash
            title={" Sales"}
            value={
              dashboardCount?.dataType === "Sale" &&
              dash?.totalSales?.toFixed(2)
            }
          />
        </button>
      </div>
      <TotalCustomers
        customerData={customerData}
        totalCustomers={totalCustomers}
        getCustomerDataById={getCustomerDataById}
      />
      {dashboardCount && (
        <div className="flex gap-6 justify-between  mt-4  items-start">
          <div className="flex flex-col gap-3 items-start ">
            {Object.keys(dashboardCount).map((item, index) => {
              return item !== "dataType" ? (
                item === "salary" &&
                dashboardCount.dataType !== "Purchase" ? null : (
                  <button
                    onClick={async () => {
                      setLoading(true);
                      if (dashboardCount.dataType === "Purchase") {
                        if (item === "salary") {
                          const [success, data] = await getDashboardSalary(
                            startDate,
                            endDate
                          );
                          if (success) {
                            setDashboardData(data);
                          }
                        } else {
                          const [success, data] = await getDashboardPurchase(
                            item,
                            startDate,
                            endDate
                          );
                          if (success) {
                            setDashboardData(data);
                          }
                        }
                      } else if (dashboardCount.dataType === "Profit") {
                        const [success, data] = await getDashboardProfit(
                          item,
                          startDate,
                          endDate
                        );
                        if (success) {
                          setDashboardData(data);
                        }
                      } else {
                        const [success, data] = await getDashboardSale(
                          item,
                          startDate,
                          endDate
                        );
                        if (success) {
                          setDashboardData(data);
                        }
                      }
                      setLoading(false);
                    }}
                    key={index}
                    className="hover:bg-primary px-2 py-1 rounded-md "
                  >
                    <h1 className="font-medium ">
                      {item}( Rs {dashboardCount[item].toFixed(2)})
                    </h1>
                  </button>
                )
              ) : (
                <h1 className="font-medium bg-secondary border border-black rounded px-4 py-1  ">
                  {dashboardCount[item]}
                </h1>
              );
            })}
          </div>
          {dashboardData?.length > 0 &&
            (dashboardData?.[0]?.type === "Salary" ? (
              <div className="flex flex-col gap-3 items-start pl-6">
                <h1 className="font-medium bg-secondary border border-black rounded px-4 py-1  ">
                  Salary Data
                </h1>
                <Table
                  data={[
                    ...dashboardData,
                    {
                      staff: {
                        userName: "Total",
                      },
                      amount: dashboardData.reduce(
                        (acc, item) => acc + item.amount,
                        0
                      ),
                    },
                  ]}
                  columns={[
                    {
                      title: "Name",
                      render: (record) => (
                        <p
                          className={
                            record?.staff?.userName === "Total"
                              ? "font-bold text-blue-700"
                              : ""
                          }
                        >
                          {record?.staff?.userName}
                        </p>
                      ),
                    },
                    {
                      title: "Amount",
                      render: (record) => record?.amount,
                    },
                  ]}
                />
              </div>
            ) : (
              <div className="flex flex-col gap-3 items-start pl-6">
                <h1 className="font-medium bg-secondary border border-black rounded px-4 py-1  ">
                  {dashboardCount.dataType} Data
                </h1>
                <Table
                  data={dashboardData?.filter(
                    (item) => dashboardCount.dataType === item?.productType
                  )}
                  columns={[
                    {
                      title: "Product Name",
                      render: (record) => record?.productName,
                    },
                    {
                      title:
                        dashboardCount.dataType === "Profit"
                          ? "Price"
                          : "Quantity",
                      render: (record) => record?.total,
                    },
                  ]}
                />
              </div>
            ))}
        </div>
      )}
      {open && (
        <Table
          data={pendingOrders}
          columns={[
            {
              title: "Date",
              dataIndex: "createdAt",
              render: (record) => formatDate(record?.updatedAt),
            },
            {
              title: "Customer Name",
              render: (record) => record?.customer?.userName,
            },
            {
              title: "description",
              dataIndex: "description",
            },
            {
              title: "Location",
              render: (record) => record?.customer?.group?.name,
            },
            {
              title: "Product Name",
              render: (record) => record?.product?.productName,
            },
            {
              title: "Qnt",
              dataIndex: "quantity",
            },
            {
              title: "Rate",
              dataIndex: "rate",
            },
            {
              title: "Gst",
              dataIndex: "gst",
            },
            {
              title: "Total",
              dataIndex: "totalAmount",
              render: (record) => record.totalAmount.toFixed(2),
            },
            {
              title: "User",
              render: (row) => (
                <div>
                  {row?.createdByName},{row?.completedByName}
                </div>
              ),
            },
            {
              title: "Image",
              render: (row) => (
                <a href={row?.customer?.image} target="_blank" rel="noreferrer">
                  {row?.customer?.image ? "View" : ""}
                </a>
              ),
            },
            {
              title: "PDF",
              render: (record) => (
                <div className="flex items-center justify-center gap-3">
                  <button
                    className="bg-red-600  px-2 py-1 "
                    onClick={() => {
                      setUpdate(true);
                      setNewOrder({
                        _id: record._id,
                        customerId: record.customer._id,
                        productId: record.product._id,
                        description: record.description,
                        quantity: record.quantity,
                        rate: record.rate,
                        gst: record.gst,
                      });
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="bg-green-500  px-2 py-1 "
                    onClick={async () => {
                      setLoading(true);
                      const [success, data] = await completeOrder(record._id, {
                        type: "admin",
                        completedById: user.admin._id,
                      });
                      if (success) {
                        toast.success("Order Completed");
                        getAllOrdersHandler();
                      } else {
                        toast.error(data);
                      }
                      setLoading(false);
                    }}
                  >
                    Complete
                  </button>
                  <button
                    className="bg-red-600  px-2 py-1 text-2xl"
                    onClick={async () => {
                      setLoading(true);
                      const [success, data] = await deleteOrder(record._id);
                      if (success) {
                        toast.success("Order Deleted");
                        getAllOrdersHandler();
                      } else {
                        toast.error(data);
                      }
                      setLoading(false);
                    }}
                  >
                    <IoIosClose />
                  </button>
                </div>
              ),
            },
          ]}
        />
      )}
      <CustomModal
        open={update}
        setOpen={setUpdate}
        loading={loading}
        title={"Update Order"}
        onClick={async () => {
          setLoading(true);
          const [success, data] = await updateOrder(newOrder._id, {
            customerId: newOrder.customerId,
            productId: newOrder.productId,
            description: newOrder.description,
            quantity: newOrder.quantity,
            rate: newOrder.rate,
            gst: newOrder.gst,
          });
          if (success) {
            toast.success("Order Updated");
            setUpdate(false);
            getAllOrdersHandler();
          } else {
            toast.error(data);
          }
          setLoading(false);
        }}
      >
        <div className="flex    gap-3 flex-col ">
          <div className="flex justify-between flex-col md:flex-row  gap-2">
            <div className="">Customer</div>
            <CustomSelect
              width={"256px"}
              onChange={(e) => {
                setNewOrder({ ...newOrder, customerId: e.target.value });
              }}
              options={customers.map((customer) => ({
                value: customer._id,
                label: customer.userName,
              }))}
              value={newOrder.customerId}
            />
          </div>
          <ModalInput
            title={"description"}
            value={newOrder?.description}
            onChange={(e) => {
              setNewOrder({ ...newOrder, description: e.target.value });
            }}
          />

          <div className="flex justify-between flex-col md:flex-row   gap-2">
            <div className="">Items</div>
            <CustomSelect
              width={"256px"}
              onChange={(e) => {
                setNewOrder({ ...newOrder, productId: e.target.value });
              }}
              options={products.map((item) => ({
                value: item._id,
                label: item.productName,
              }))}
              value={newOrder?.productId}
            />
          </div>

          <ModalInput
            type={"number"}
            title={"Quantity"}
            onChange={(e) => {
              setNewOrder({ ...newOrder, quantity: e.target.value });
            }}
            value={newOrder?.quantity}
          />

          <ModalInput
            title={"GST"}
            type={"number"}
            onChange={(e) => {
              setNewOrder({ ...newOrder, gst: e.target.value });
            }}
            value={newOrder?.gst}
          />
          <ModalInput
            title={"Rate"}
            type={"number"}
            onChange={(e) => {
              setNewOrder({ ...newOrder, rate: e.target.value });
            }}
            value={newOrder?.rate}
          />
        </div>
      </CustomModal>
      <ProfitAndPurchase dashboardCount={dashboardCount} dash={dash} />

      {dashboardCount?.dataType === "Purchase" && (
        <Table
          data={[...purchaseOrders]?.reverse()}
          columns={[
            {
              title: "Date",
              dataIndex: "date",
              render: (record) => (
                <div
                  className={`${
                    record?.paymentType === "Salary"
                      ? "bg-yellow-400 px-3  border border-black"
                      : ""
                  }`}
                >
                  {formatDate(record?.date)}
                </div>
              ),
            },
            {
              title: "Customer Name",
              render: (record) => (
                <div
                  className={`${
                    record?.paymentType === "Salary"
                      ? "bg-yellow-400 px-3  border border-black"
                      : ""
                  }`}
                >
                  {record?.paymentType === "Salary"
                    ? record?.staff?.userName
                    : record?.customer?.userName}
                </div>
              ),
            },
            {
              title: "description",
              dataIndex: "description",
            },
            {
              title: "Location",
              render: (record) => record?.customer?.group?.name,
            },
            {
              title: "Product Name",
              render: (record) => record?.product?.productName,
            },
            {
              title: "Qnt",
              dataIndex: "quantity",
            },
            {
              title: "Rate",
              dataIndex: "rate",
            },
            {
              title: "Gst",
              dataIndex: "gst",
            },
            {
              title: "Total",
              dataIndex: "totalAmount",
              render: (record) => (
                <div
                  className={`${
                    record?.paymentType === "Salary"
                      ? "bg-yellow-400 px-3  border border-black"
                      : ""
                  }`}
                >
                  {record?.paymentType === "Salary"
                    ? record?.amount?.toFixed(2)
                    : record.totalAmount?.toFixed(2)}
                </div>
              ),
            },
            {
              title: "Completed By",
              dataIndex: "CompletedAmount",
              render: (record) => (
                <div
                  className={`${
                    record?.paymentType === "Salary"
                      ? "bg-yellow-400 px-3  border border-black"
                      : ""
                  }`}
                >
                  {record?.completedByName}
                </div>
              ),
            },
            {
              title: "User",
              render: (row) => row.createdByName,
            },
            {
              title: "Image",
              render: (row) => (
                <a href={row?.customer?.image} target="_blank" rel="noreferrer">
                  {row?.customer?.image ? "View" : ""}
                </a>
              ),
            },
            {
              title: "PDF",
              link: "/admin/pdf/purchase",
            },
            // {
            //   title: "PDF",
            //   render: (record) => (
            //     <div className="flex items-center justify-center gap-3">
            //       <button
            //         className="bg-red-600  px-2 py-1 "
            //         onClick={() => {
            //           setUpdate(true);
            //           setNewOrder({
            //             _id: record._id,
            //             customerId: record.customer._id,
            //             productId: record.product._id,
            //             description: record.description,
            //             quantity: record.quantity,
            //             rate: record.rate,
            //             gst: record.gst,
            //           });
            //         }}
            //       >
            //         Edit
            //       </button>
            //       <button
            //         className="bg-red-600  px-2 py-1 text-2xl"
            //         onClick={async () => {
            //           setLoading(true);
            //           const [success, data] = await deleteOrder(record._id);
            //           if (success) {
            //             toast.success("Order Deleted");
            //             getAllCompletedOrdersHandler();
            //           } else {
            //             toast.error(data);
            //           }
            //           setLoading(false);
            //         }}
            //       >
            //         <IoIosClose />
            //       </button>
            //     </div>
            //   ),
            // },
          ]}
        />
      )}
      {dashboardCount?.dataType === "Sale" && (
        <Table
          data={[...completedOrders]?.reverse()}
          columns={[
            {
              title: "Date",
              dataIndex: "updatedAt",
              render: (record) =>
                record?.completedDate
                  ? formatDate(record?.completedDate)
                  : formatDate(record?.updatedAt),
            },
            {
              title: "Customer Name",

              render: (record) => (
                <p>
                  {record?.customer?.userName}{" "}
                  {record?.clients?.length > 0 && (
                    <>
                      (
                      {record?.clients?.map((item) => item.userName)?.join(",")}
                      )
                    </>
                  )}
                </p>
              ),
            },
            {
              title: "description",
              dataIndex: "description",
            },
            {
              title: "Location",
              render: (record) => record?.customer?.group?.name,
            },
            {
              title: "Product Name",
              render: (record) => record?.product?.productName,
            },
            {
              title: "Qnt",
              dataIndex: "quantity",
            },
            {
              title: "Rate",
              dataIndex: "rate",
            },
            {
              title: "Gst",
              dataIndex: "gst",
            },
            {
              title: "Total",
              dataIndex: "totalAmount",
              render: (record) => record?.totalAmount?.toFixed(2),
            },
            {
              title: "PDF",
              link: "/admin/pdf/sales",
            },
            // {
            //   title: "PDF",
            //   render: (record) => (
            //     <div className="flex items-center justify-center gap-3">
            //       <button
            //         className="bg-red-600  px-2 py-1 "
            //         onClick={() => {
            //           setUpdate(true);
            //           setNewOrder({
            //             _id: record._id,
            //             customerId: record.customer._id,
            //             productId: record.product._id,
            //             description: record.description,
            //             quantity: record.quantity,
            //             rate: record.rate,
            //             gst: record.gst,
            //           });
            //         }}
            //       >
            //         Edit
            //       </button>
            //       <button
            //         className="bg-red-600  px-2 py-1 text-2xl"
            //         onClick={async () => {
            //           setLoading(true);
            //           const [success, data] = await deleteOrder(record._id);
            //           if (success) {
            //             toast.success("Order Deleted");
            //             getAllCompletedOrdersHandler();
            //           } else {
            //             toast.error(data);
            //           }
            //           setLoading(false);
            //         }}
            //       >
            //         <IoIosClose />
            //       </button>
            //     </div>
            //   ),
            // },
          ]}
        />
      )}
    </Layout>
  );
};

export default Dashboard;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ProfitAndPurchase = ({ dashboardCount, dash }) => {
  const [value, setValue] = React.useState(0);
  console.log(dash);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [payments, setPayments] = useState({});
  const [loading, setLoading] = useState(false);
  const getAllPaymentsHandler = async () => {
    setLoading(true);
    const [success, data] = await dashboardProfit();
    console.log(data);
    if (success) {
      setPayments(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllPaymentsHandler();
  }, []);
  return (
    <>
      {dashboardCount?.dataType === "Profit" && (
        <Box sx={{ width: "100%", marginTop: "20px" }}>
          <div className="flex gap-6">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label={`Payment (${payments?.paymentAmount})`}
                  {...a11yProps(0)}
                />

                <Tab
                  label={`Received (${payments?.receivedAmount})`}
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
            <div className="z-[30]  rounded-full  flex items-center justify-center">
              Balance (Without GST) :{payments?.balanceWithoutGst?.toFixed(2)}
            </div>
            <div className="z-[30]  rounded-full  flex items-center justify-center">
              Balance (With GST) :{payments?.balanceWithGst?.toFixed(2)}
            </div>
            <div className="z-[30]  rounded-full  flex items-center justify-center">
              Due Payment :{payments?.duePayment?.toFixed(2)}
            </div>
            <div className="z-[30]  rounded-full  flex items-center justify-center">
              Adjustment :{payments?.adjustment?.toFixed(2)}
            </div>
          </div>
          <CustomTabPanel value={value} index={0}>
            <Table
              data={
                payments?.payments?.length > 0
                  ? [...payments?.payments]?.reverse()
                  : []
              }
              columns={[
                {
                  title: "Date",
                  dataIndex: "date",
                  render: (record) => formatDate(record?.date),
                },
                {
                  title: "Customer Name",
                  render: (record) => record?.customer?.userName,
                },
                {
                  title: "description",
                  dataIndex: "description",
                },
                {
                  title: "Payment Type",
                  dataIndex: "paymentType",
                },
                {
                  title: "Amount",
                  dataIndex: "amount",
                  render: (record) => `₹ ${record.amount?.toFixed(2)}`,
                },
                {
                  title: "User",
                  dataIndex: "completedByName",
                },
                {
                  title: "PDF",
                  link: "/admin/pdf/profit",
                },
              ]}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Table
              data={
                payments?.received?.length > 0
                  ? [...payments?.received]?.reverse()
                  : []
              }
              columns={[
                {
                  title: "Date",
                  dataIndex: "date",
                  render: (record) => formatDate(record?.date),
                },
                {
                  title: "Customer Name",
                  render: (record) =>
                    record?.type === "PurchaseOrder"
                      ? record?.clients?.userName
                      : record?.customer?.userName,
                },
                {
                  title: "description",
                  dataIndex: "description",
                },
                {
                  title: "Payment Type",
                  dataIndex: "type",
                  render: (record) =>
                    record.type === "PurchaseOrder"
                      ? "PurchaseOrder"
                      : record.paymentType,
                },

                {
                  title: "Amount",
                  dataIndex: "amount",
                  render: (record) =>
                    `₹ ${
                      record.amount?.toFixed(2) ??
                      record?.totalAmount?.toFixed(2)
                    }`,
                },
                { title: "User", dataIndex: "completedByName" },
                {
                  title: "PDF",
                  link: "/admin/pdf/received",
                },
              ]}
            />
          </CustomTabPanel>
        </Box>
      )}
    </>
  );
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
