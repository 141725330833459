import React from "react";
import { IoIosClose } from "react-icons/io";
import { toast } from "react-toastify";
import Dash from "../../../components/Dash";
import Layout3 from "../../../components/Layout/Layout3";
import Loading from "../../../components/Loading";
import CustomModal, { ModalInput } from "../../../components/Modal";
import CustomSelect from "../../../components/Select";
import Table from "../../../components/Table";
import {
  completeOrder,
  deleteOrder,
  getAllCustomers,
  getMarketDashboardHandler,
  getProductData,
  getstaffbalanceData,
  getstaffDatabyid,
  getstaffPaymentData,
  getstaffreceviedData,
  updateOrder,
} from "../../../utils/api";
import { formatDate } from "../../../utils/formatData";
import useStore from "../../../utils/store";

const StaffMarketDash = () => {
  const [dashboard, setDashboard] = React.useState([]);
  const [customers, setCustomers] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [newOrder, setNewOrder] = React.useState({});
  const [update, setUpdate] = React.useState(false);
  const user = useStore((state) => state.user);
  const getDashboard = async () => {
    setLoading(true);
    const [success, data] = await getMarketDashboardHandler();
    if (success) {
      setDashboard(data);
    }
    setLoading(false);
  };

  const getCustomers = async () => {
    setLoading(true);
    const [success, data] = await getAllCustomers();

    if (success) {
      setCustomers(data);
    }
    setLoading(false);
  };

  const getProductsHandler = async () => {
    setLoading(true);
    const [success, data] = await getProductData();
    if (success) {
      setProducts(data);
    }
    setLoading(false);
  };
  const [staff, setStaff] = React.useState(null);
  const [data, setData] = React.useState(null);

  const getStaff = async () => {
    setLoading(true);
    const [success, data] = await getstaffDatabyid(user?.staff?._id);
    if (success) {
      setStaff(data);
    }
    setLoading(false);
  };
  React.useEffect(() => {
    getDashboard();
    getCustomers();
    getProductsHandler();
    getStaff();
  }, []);
  return (
    <Layout3 title={user?.staff?.userName}>
      {loading && <Loading />}

      <div className="flex-1 flex flex-col w-full">
        <div className="flex-1 flex gap-6">
          <button
            onClick={async () => {
              const [success, data] = await getstaffreceviedData(
                user?.staff?._id
              );
              if (success) {
                setData(data);
              }
            }}
            className="flex-1"
          >
            <Dash
              title={"Received"}
              value={staff?.record?.received?.toFixed(2)}
            />
          </button>{" "}
          <button
            onClick={async () => {
              const [success, data] = await getstaffPaymentData(
                user?.staff?._id
              );
              if (success) {
                setData(data);
              }
            }}
            className="flex-1"
          >
            <Dash
              title={"Payment"}
              value={staff?.record?.payment?.toFixed(2)}
            />
          </button>
          <button
            onClick={async () => {
              const [success, data] = await getstaffbalanceData(
                user?.staff?._id
              );
              if (success) {
                setData(data);
              }
            }}
            className="flex-1"
          >
            <Dash title={"Balance"} value={staff?.staffBalance?.toFixed(2)} />
          </button>
          {/* <button
            onClick={async () => {
              const [success, data] = await getstaffSalaryData(
                user?.staff?._id
              );
              if (success) {
                setData(data);
              }
            }}
            className="flex-1"
          >
            <Dash title={"Salary"} value={staff?.salary} />
          </button> */}
        </div>
        {data && (
          <div className="mt-3 ">
            <h1 className="text-2xl font-semibold">
              {data?.[0]?.paymentType
                ? data?.[0]?.paymentType
                : "Purchase Items"}
            </h1>
            {data?.[0]?.paymentType ? (
              <Table
                data={[...data]?.reverse()}
                columns={[
                  {
                    title: "Date",
                    dataIndex: "date",
                    render(item) {
                      return formatDate(item?.date);
                    },
                  },
                  {
                    title: "Customer",
                    accessor: "customer",
                    render(item) {
                      return item?.customer?.userName;
                    },
                  },

                  {
                    title: "Description",
                    dataIndex: "description",
                  },
                  {
                    title: "Location",
                    dataIndex: "location",
                    render(item) {
                      return item?.customer?.group?.name;
                    },
                  },

                  {
                    title: "Type",
                    dataIndex: "paymentType",
                  },
                  {
                    title: "Total",
                    dataIndex: "amount",
                    render: (record) => record?.amount?.toFixed(2),
                  },
                  {
                    title: "Completed By",
                    dataIndex: "completedByName",
                  },

                  // {
                  //   title: "PDF",
                  //   link: `/pdf/admin/staff/${data?.[0]?.paymentType}/${item?._id}`,
                  // },
                ]}
              />
            ) : (
              <Table
                data={[...data]?.reverse()}
                columns={[
                  {
                    title: "Date",
                    dataIndex: "date",
                    render(item) {
                      return formatDate(item?.date);
                    },
                  },
                  {
                    title: "Customer",
                    accessor: "customer",
                    render(item) {
                      return item?.customer?.userName;
                    },
                  },

                  {
                    title: "Description",
                    dataIndex: "description",
                  },
                  {
                    title: "Location",
                    dataIndex: "location",
                    render(item) {
                      return item?.customer?.group?.name;
                    },
                  },
                  {
                    title: "Product Name",
                    dataIndex: "location",
                    render(item) {
                      return item?.product?.productName;
                    },
                  },
                  {
                    title: "Qnt",
                    dataIndex: "quantity",
                  },
                  {
                    title: "Rate",
                    dataIndex: "rate",
                  },
                  {
                    title: "Gst",
                    dataIndex: "gst",
                  },
                  {
                    title: "Total",
                    dataIndex: "totalAmount",
                    render: (record) => record?.totalAmount?.toFixed(2),
                  },
                  {
                    title: "User",
                    dataIndex: "completedByName",
                  },

                  // {
                  //   title: "PDF",
                  //   link: `/pdf/admin/staff/Balance/${item?._id}`,
                  // },
                ]}
              />
            )}
          </div>
        )}
      </div>

      <div className="overflow-x-auto w-full">
        <Table
          data={dashboard}
          columns={[
            {
              title: "Date",
              dataIndex: "createdAt",
              render: (record) => formatDate(record?.createdAt),
            },
            {
              title: "Customer Name",
              render: (record) => record?.customer?.userName,
            },
            {
              title: "description",
              dataIndex: "description",
            },
            {
              title: "Location",
              render: (record) => record?.customer?.group?.name,
            },
            {
              title: "Product Name",
              render: (record) => record?.product?.productName,
            },
            {
              title: "Qnt",
              dataIndex: "quantity",
            },
            {
              title: "Rate",
              dataIndex: "rate",
            },
            {
              title: "Gst",
              dataIndex: "gst",
            },
            {
              title: "Total",
              dataIndex: "totalAmount",
              render: (record) => record?.totalAmount?.toFixed(2),
            },
            {
              title: "User",
              render: (row) => (
                <div>
                  {row?.createdByName},{row?.completedByName}
                </div>
              ),
            },
            {
              title: "Image",
              render: (row) => (
                <a href={row?.customer?.image} target="_blank" rel="noreferrer">
                  {row?.customer?.image ? "View" : ""}
                </a>
              ),
            },
            {
              title: "PDF",
              render: (record) => (
                <div className="flex items-center justify-center gap-3">
                  <button
                    className="bg-red-600  px-2 py-1 "
                    onClick={() => {
                      setUpdate(true);
                      setNewOrder({
                        _id: record._id,
                        customerId: record.customer._id,
                        productId: record.product._id,
                        description: record.description,
                        quantity: record.quantity,
                        rate: record.rate,
                        gst: record.gst,
                      });
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="bg-green-500  px-2 py-1 "
                    onClick={async () => {
                      setLoading(true);
                      const [success, data] = await completeOrder(record._id, {
                        type: "staff",
                        completedById: user.staff._id,
                      });
                      if (success) {
                        toast.success("Order Completed");
                        getDashboard();
                      } else {
                        toast.error(data);
                      }
                      setLoading(false);
                    }}
                  >
                    Complete
                  </button>
                  <button
                    className="bg-red-600  px-2 py-1 text-2xl"
                    onClick={async () => {
                      setLoading(true);
                      const [success, data] = await deleteOrder(record._id);
                      if (success) {
                        toast.success("Order Deleted");
                        getDashboard();
                      } else {
                        toast.error(data);
                      }
                      setLoading(false);
                    }}
                  >
                    <IoIosClose />
                  </button>
                </div>
              ),
            },
          ]}
        />
      </div>

      <CustomModal
        open={update}
        setOpen={setUpdate}
        loading={loading}
        title={"Update Order"}
        onClick={async () => {
          setLoading(true);
          const [success, data] = await updateOrder(newOrder._id, {
            customerId: newOrder.customerId,
            productId: newOrder.productId,
            description: newOrder.description,
            quantity: newOrder.quantity,
            rate: newOrder.rate,
            gst: newOrder.gst,
          });
          if (success) {
            toast.success("Order Updated");
            setUpdate(false);
            getDashboard();
          } else {
            toast.error(data);
          }
          setLoading(false);
        }}
      >
        <div className="flex    gap-3 flex-col ">
          <div className="flex justify-between flex-col md:flex-row  gap-2">
            <div className="">Customer</div>
            <CustomSelect
              width={"256px"}
              onChange={(e) => {
                setNewOrder({ ...newOrder, customerId: e.target.value });
              }}
              options={customers.map((customer) => ({
                value: customer._id,
                label: customer.userName,
              }))}
              value={newOrder.customerId}
            />
          </div>
          <ModalInput
            title={"description"}
            value={newOrder?.description}
            onChange={(e) => {
              setNewOrder({ ...newOrder, description: e.target.value });
            }}
          />

          <div className="flex justify-between flex-col md:flex-row   gap-2">
            <div className="">Items</div>
            <CustomSelect
              width={"256px"}
              onChange={(e) => {
                setNewOrder({ ...newOrder, productId: e.target.value });
              }}
              options={products.map((item) => ({
                value: item._id,
                label: item.productName,
              }))}
              value={newOrder?.productId}
            />
          </div>

          <ModalInput
            type={"number"}
            title={"Quantity"}
            onChange={(e) => {
              setNewOrder({ ...newOrder, quantity: e.target.value });
            }}
            value={newOrder?.quantity}
          />

          <ModalInput
            title={"GST"}
            type={"number"}
            onChange={(e) => {
              setNewOrder({ ...newOrder, gst: e.target.value });
            }}
            value={newOrder?.gst}
          />
          <ModalInput
            title={"Rate"}
            type={"number"}
            onChange={(e) => {
              setNewOrder({ ...newOrder, rate: e.target.value });
            }}
            value={newOrder?.rate}
          />
        </div>
      </CustomModal>
    </Layout3>
  );
};

export default StaffMarketDash;
