import classNames from "classnames";
import React, { useState } from "react";
import CustomerDetailSubPage from "../../components/CustomerDetailSubPage";
import Layout from "../../components/Layout/Layout";
import Loading from "../../components/Loading";
import Table from "../../components/Table";
import { getCustomerDashData, getCustomerGroup } from "../../utils/api";
import useStore from "../../utils/store";
const CustomerDetailsGroup = () => {
  const [id, setId] = React.useState(null);
  const [totalCustomers, setTotalCustomers] = useState(null);

  const [customerData, setCustomerData] = useState([]);
  const [loading, setLoading] = useState(false);
  const startDate = useStore((state) => state.startDate);
  const endDate = useStore((state) => state.endDate);
  const getCustomerDataById = async (id) => {
    setLoading(true);
    const [success, data] = await getCustomerDashData(id, startDate, endDate);
    if (success) {
      setCustomerData(data.map((item) => ({ ...item, id: id, _id: item.id })));
    }
    setLoading(false);
  };

  const getCustomerGroupHandler = async () => {
    setLoading(true);
    const [success, data] = await getCustomerGroup(startDate, endDate);
    if (success) {
      setTotalCustomers(data);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getCustomerGroupHandler();
  }, []);
  return (
    <Layout>
      {loading && <Loading />}
      {totalCustomers && (
        <div className="flex flex-col md:flex-row gap-6 justify-between ">
          <Table
            data={totalCustomers}
            columns={
              customerData.length > 0
                ? [
                    {
                      title: "Group Names",
                      dataIndex: "groupName",
                      render: (record) => (
                        <button
                          onClick={() => {
                            getCustomerDataById(record._id);
                            setId(record._id);
                          }}
                          className={` border-b  ${
                            customerData?.[0].id === record._id
                              ? "text-green-600 font-bold border-green-600"
                              : `text-blue-900 border-blue-900`
                          }`}
                        >
                          {record.groupName}
                        </button>
                      ),
                    },

                    {
                      title: "Products",
                      dataIndex: "products",
                      render: (record) =>
                        customerData?.[0].id === record._id && (
                          <div className="flex flex-col gap-2">
                            {record.products.map((item, index) => (
                              <p key={index}>
                                {item.productName}({item.quantity})
                              </p>
                            ))}
                            <p
                              className={classNames(
                                "font-bold ",
                                customerData?.reduce(
                                  (acc, item) => acc + item.balance,
                                  0
                                ) < 0
                                  ? "text-red-700"
                                  : "text-green-700"
                              )}
                            >
                              Total Balance :{" "}
                              {customerData
                                ?.reduce((acc, item) => acc + item.balance, 0)
                                ?.toFixed(2)}
                            </p>
                          </div>
                        ),
                    },
                  ]
                : [
                    {
                      title: "Group Name",
                      dataIndex: "groupName",
                      render: (record) => (
                        <button
                          onClick={() => getCustomerDataById(record._id)}
                          className="text-blue-900 border-b border-blue-900"
                        >
                          {record.groupName}
                        </button>
                      ),
                    },
                    {
                      title: "Total Customers",
                      dataIndex: "customers",
                    },
                  ]
            }
          />
          {customerData.length > 0 && (
            <CustomerDetailSubPage customerData={customerData} id={id} />
          )}
        </div>
      )}
    </Layout>
  );
};

export default CustomerDetailsGroup;
