import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useStore from "../../utils/store";
import Head from "../Head";
import Sidebar from "./Sidebar";

const Layout4 = ({ children, type, title, subTitle }) => {
  const user = useStore((state) => state.user);
  const setUser = useStore((state) => state.setUser);
  const navigate = useNavigate();
  console.log(user);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (!user || user?.type !== type) {
      navigate("/login");
    }
  }, [user, navigate]);
  return (
    <div className="flex h-[100dvh] bg-layoutbg">
      <Sidebar
        open={open}
        setOpen={setOpen}
        links={
          user?.type === "Dispatch"
            ? [
                {
                  title: "Dashboard",
                  path: "/staff/dispatch",
                },
                {
                  title: "Customers",
                  path: "/staff/dispatch/add",
                },
                {
                  title: "Logout",
                  path: "/logout",
                },
              ]
            : [
                {
                  title: "Logout",
                  path: "/logout",
                },
              ]
        }
      />

      <div className="overflow-y-auto w-full ">
        <Head title={title} menu={() => setOpen(true)} />
        <div className="px-6 py-4">{children}</div>
      </div>
    </div>
  );
};

export default Layout4;
