import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ApiSearch from "../../components/ApiSearch";
import ClientTable from "../../components/ClientTable";
import Dash from "../../components/Dash";
import Layout from "../../components/Layout/Layout";
import Loading from "../../components/Loading";
import CustomModal, {
  ModalDatePicker,
  ModalInput,
  ModalSelect3,
} from "../../components/Modal";
import InvoicesModal from "../../components/modals/InvoicesModal";
import {
  addGSTToCusPro,
  addRateToCusPro,
  getAllCustomers,
  getCustomersById,
  getInvoiceNo,
  getProductData,
  getProductGstByCustomer,
  getProductPriceByCustomer,
  isGstInvoiceNumberValid,
  submitInvoiceDetail,
  updateGSTToCusPro,
  updateRateToCusPro,
} from "../../utils/api";
import useStore from "../../utils/store";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CustomerInfo = ({
  customer,
  currentCustomer,
  customerId,
  onCreateInvoice,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rate, setRate] = useState(0);
  const [text, setText] = useState(0);
  const [product, setProduct] = useState(null);
  const [gst, setGst] = useState(0);
  const [text2, setText2] = useState(0);
  const [products, setProducts] = useState([]);
  const getProductPrice = async (data) => {
    setLoading(true);

    const [success, data2] = await getProductPriceByCustomer(data);
    if (success) {
      setRate(data2);
      if (data2) {
        setText(data2?.rate);
      } else {
        setText(0);
      }
    }

    setLoading(false);
  };

  const getProductGSt = async (data) => {
    setLoading(true);

    const [success, data2] = await getProductGstByCustomer(data);
    if (success) {
      setGst(data2);
      if (data2) {
        setText2(data2?.gst);
      } else {
        setText2(0);
      }
    }

    setLoading(false);
  };

  const getProductsData = async () => {
    setLoading(true);
    const [success, data] = await getProductData();
    if (success) {
      setProducts(data);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (product) {
      getProductPrice({
        customerId,
        productId: product,
      });
      getProductGSt({
        customerId,
        productId: product,
      });
    }
  }, [product]);
  console.log(product);
  useEffect(() => {
    if (open) {
      getProductsData();
    }
  }, [open]);

  return (
    <div className="flex gap-6 flex-wrap">
      <div className="flex text-red-500 w-fit font-semibold bg-yellow-400 px-3 py-2 border border-black">
        {customer?.name}
      </div>
      <div className="flex text-black w-fit font-semibold bg-secondary px-3 py-2 border border-black">
        Wagon : {customer?.wagon}
      </div>
      <div className="flex text-black w-fit font-semibold bg-secondary px-3 py-2 border border-black">
        Tag : {customer?.wagon * 580}
      </div>
      <div className="flex text-black w-fit font-semibold bg-secondary px-3 py-2 border border-black">
        Completed Tag : {customer?.orderTagAmount}
      </div>
      <div className="flex text-black w-fit font-semibold bg-secondary px-3 py-2 border border-black">
        Balance : {customer?.bal}
      </div>
      {customer?.phoneNumber && (
        <div className="flex text-black w-fit font-semibold bg-secondary px-3 py-2 border border-black">
          PhoneNumber : {customer?.phoneNumber}
        </div>
      )}
      <InvoicesModal id={customerId} />
      <div
        className="flex text-white w-fit font-semibold bg-primary px-3 py-2 border border-black cursor-pointer"
        onClick={onCreateInvoice}
      >
        Create Bill
      </div>
      <div
        onClick={() => setOpen(true)}
        className="flex text-white w-fit font-semibold bg-primary px-3 py-2 border border-black cursor-pointer"
      >
        Add Rate / GST
      </div>
      <Link
        className="flex text-white w-fit font-semibold bg-primary px-3 py-2 border border-black cursor-pointer"
        to={`/pdf/ledger/customer/${customerId}`}
      >
        Ledger
      </Link>

      <CustomModal
        open={open}
        setOpen={setOpen}
        title={rate ? "Update Rate" : "Add Rate"}
        button={<div className="flex gap-2"></div>}
      >
        <ModalSelect3
          title="Product"
          options={products
            ?.filter((item) => item.productType === "Sale")
            .map((item) => ({
              label: item.productName,
              value: item._id,
            }))}
          onChange={(e) => {
            setProduct(e.target.value);
          }}
        />
        {product && (
          <>
            <div className="flex flex-col gap-2">
              <label className="text-black font-semibold">Rate</label>
              <input
                type="number"
                placeholder="Rate"
                className="border border-black rounded p-2"
                disabled={!customer}
                value={text}
                onChange={(e) => {
                  setText(e.target.value);
                }}
              />
            </div>
            <button
              className="bg-primary text-white rounded p-2 ml-auto"
              disabled={!customer}
              onClick={async () => {
                if (text === null || text === undefined || text === "") {
                  toast.error("Please enter rate");
                }

                if (!customer) {
                  toast.error("Please select customer and product");
                }

                setLoading(true);
                if (rate === null) {
                  const [success, data] = await addRateToCusPro({
                    customerId,
                    productId: product,
                    rate: +text,
                  });
                  if (success) {
                    toast.success("Rate added successfully");
                    getProductPrice({
                      customerId,
                      productId: product,
                    });
                  }
                } else {
                  const [success, data] = await updateRateToCusPro(rate._id, {
                    rate: +text,
                  });
                  if (success) {
                    toast.success("Rate updated successfully");
                    getProductPrice({
                      customerId: customer,
                      productId: product?._id,
                    });
                  }
                }
                setLoading(false);
              }}
            >
              {!loading ? (rate ? "Update Rate" : "Add Rate") : "Loading..."}
            </button>
            <div className="flex flex-col gap-2">
              <label className="text-black font-semibold">GST</label>
              <input
                type="number"
                placeholder="GST"
                className="border border-black rounded p-2"
                disabled={!customer}
                value={text2}
                onChange={(e) => {
                  setText2(e.target.value);
                }}
              />
            </div>
            <button
              className="bg-primary text-white rounded p-2 ml-auto"
              disabled={!product || !customer}
              onClick={async () => {
                if (text2 === null || text2 === undefined || text2 === "") {
                  toast.error("Please enter gst");
                }

                if (!customer || !product) {
                  toast.error("Please select customer and product");
                }

                setLoading(true);
                if (gst === null) {
                  const [success, data] = await addGSTToCusPro({
                    customerId,
                    productId: product,
                    gst: +text2,
                  });
                  if (success) {
                    toast.success("Gst added successfully");
                    getProductGSt({
                      customerId,
                      productId: product,
                    });
                  }
                } else {
                  const [success, data] = await updateGSTToCusPro(gst._id, {
                    gst: +text2,
                  });
                  if (success) {
                    toast.success("Gst updated successfully");
                    getProductGSt({
                      customerId,
                      productId: product,
                    });
                  }
                }
                setLoading(false);
              }}
            >
              {!loading ? (gst ? "Update Gst" : "Add Gst") : "Loading..."}
            </button>
          </>
        )}
      </CustomModal>
    </div>
  );
};

const InvoiceForm = ({
  isPartialInvoice,
  setIsPartialInvoice,
  invoiceNo,
  setInvoiceNo,
  invoiceDate,
  setInvoiceDate,
  setInvoiceData,
  invoiceData,
  productList,
  setProduct,
  setOrderIds,
  partialQuantity,
  setPartialQuantity,
}) => (
  <>
    <ModalInput
      title="Invoice No"
      onChange={(e) => {
        setInvoiceNo(e.target.value);
      }}
      value={invoiceNo}
    />
    <ModalDatePicker
      title="Invoice Date"
      onChange={(e) => {
        setInvoiceDate(e.target.value);
        setInvoiceData({
          ...invoiceData,
          invoiceDate: e.target.value + "T13:40:31.520Z",
        });
      }}
      value={invoiceDate}
    />
    <ModalInput
      title="Note"
      onChange={(e) => {
        setInvoiceData({ ...invoiceData, note: e.target.value });
      }}
      value={invoiceData?.note || ""}
    />

    <ModalSelect3
      title="Product"
      options={productList?.map((item) => ({
        label: `${item.name} (${item.quantity})`,
        value: item._id,
      }))}
      onChange={(e) => {
        setOrderIds(
          productList?.find((item) => item._id === e.target.value)?.orderIds
        );
        setProduct(e.target.value);
      }}
    />

    {isPartialInvoice && (
      <ModalInput
        title="Quantity"
        type="number"
        onChange={(e) => {
          setPartialQuantity(e.target.value);
        }}
        value={partialQuantity}
      />
    )}
  </>
);

const Customer = () => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const query = useQuery();
  const router = useNavigate();
  const [customer, setCustomer] = useState(null);
  const [edit, setEdit] = useState(null);
  const startDate = useStore((state) => state.startDate);
  const endDate = useStore((state) => state.endDate);
  const [createInvoice, setCreateInvoice] = useState(false);
  const [invoiceNo, setInvoiceNo] = useState("");
  const [invoiceData, setInvoiceData] = useState(null);
  const [customerId, setCustomerId] = useState("");
  const [productList, setProductList] = useState(null);
  const [product, setProduct] = useState(null);
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [orderIds, setOrderIds] = useState(null);
  const [data, setData] = useState({});
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [isPartialInvoice, setIsPartialInvoice] = useState(true);
  const [partialQuantity, setPartialQuantity] = useState("");
  var label;
  const getAllCustomerHandler = async () => {
    setLoading(true);
    const [success, data] = await getAllCustomers(startDate, endDate);
    if (success) {
      setCustomers(
        data.map((item) => ({
          ...item,
          name: `${item.userName} ${
            item?.group?.name !== undefined ? `(${item?.group?.name})` : ""
          }`,
        }))
      );
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getAllCustomerHandler();
  }, [startDate, endDate]);

  const getCustomersByIdHandler = async (id, name) => {
    setLoading(true);
    const [success, data] = await getCustomersById(id, startDate, endDate);
    if (success) {
      setCustomer({
        ...data,
        name,
      });
      setCustomerId(id);
      setCurrentCustomer(customers?.find((item) => item._id === id));
    }
    setLoading(false);
  };

  const createInvoicePayload = () => {
    return {
      ...invoiceData,
      invoiceNumberString: invoiceNo,
      customerId: customerId,
      orderIds: orderIds,
      ...(isPartialInvoice && { quantity: partialQuantity }),
    };
  };

  const resetInvoiceState = () => {
    setCreateInvoice(false);
    setOrderIds(null);
    setPartialQuantity("");
  };
  console.log(productList, orderIds);
  const invoiceHandler = async (isPrint = false) => {
    const [success2, data2] = await isGstInvoiceNumberValid(customerId);
    if (success2 && !data2?.isGst) {
      toast.error("Gst Invoice Number is not valid");
      return;
    }
    console.log(createInvoicePayload());
    const valid =
      partialQuantity &&
      partialQuantity > 0 &&
      partialQuantity <=
        productList?.find((item) => item._id === product)?.quantity;

    if (!valid) {
      toast.error(
        `Please enter quantity less than ${
          productList?.find((item) => item._id === product)?.quantity
        } or enter quantity`
      );
      return;
    }
    setLoading(true);
    const endpoint = isPartialInvoice
      ? "/admin/invoice-partial"
      : "/admin/invoice";
    const payload = createInvoicePayload();

    const [success, data] = await submitInvoiceDetail(payload, endpoint);

    if (success) {
      resetInvoiceState();
      toast.success("Invoice Created successfully");
      if (isPrint) {
        router(`/admin/invoice/${data._id}`);
      }
    } else {
      toast.error(data?.response?.data?.message);
    }
    setLoading(false);
  };

  const invoiceNoHandler = async (customerId) => {
    setInvoiceLoading(true);
    const [success, data] = await getInvoiceNo({
      customerId: customerId,
    });
    if (success) {
      setInvoiceNo(data.invoiceNumberString);
      setProductList(data.products);
    }
    setInvoiceLoading(false);
  };

  useEffect(() => {
    if (query?.get("id")) {
      setData({
        cur: query?.get("id"),
      });
      const name = customers.find((item) => item._id === query?.get("id"));
      getCustomersByIdHandler(query?.get("id"), name?.name);
    }
  }, [query?.get("id")]);
  console.log(customer);

  return (
    <Layout>
      {loading && <Loading />}
      <div className="flex gap-3  flex-col md:flex-row w-full text-[90%]">
        <ApiSearch
          active={data?.cur}
          data={customers
            .filter((item) => item?.type === "Customer")
            .map((item) => ({
              ...item,
              userName: `${item.userName} ${
                item?.group?.name !== undefined ? `(${item?.group?.name})` : ""
              }`,
            }))}
          item={(item) => {
            getCustomersByIdHandler(item._id, item?.name || item?.userName);
            setData({
              cur: item._id,
            });
          }}
          customer
        />
        {customer && (
          <div className="flex flex-col gap-3 md:w-[calc(100%-18rem)] flex-1 ">
            <CustomerInfo
              customer={customer}
              currentCustomer={currentCustomer}
              customerId={customerId}
              onCreateInvoice={() => {
                invoiceNoHandler(customerId);
                setCreateInvoice(true);
              }}
            />
            <div className="flex  gap-6    flex-col md:flex-row">
              <Dash title={"Sale"} value={customer?.totalSales?.toFixed(2)} />
              <Dash
                title={"Payment Received"}
                value={customer?.totalPaymentRecived?.toFixed(2)}
              />
              <Dash
                title={"Balance"}
                value={customer?.totalBalance?.toFixed(2)}
              />
            </div>
            <ClientTable
              customers={customers}
              id={data?.cur}
              reload={() =>
                getCustomersByIdHandler(
                  data?.cur,
                  customers.find((item) => item._id === data?.curr)?.name
                )
              }
              edit={(data) => {
                setEdit(true);
                if (data.paymentType) {
                  setData({
                    _id: data._id,
                    customerId: data?.customer?._id,

                    description: data.description,
                    paymentType: data.paymentType,
                    amount: data.amount,
                  });
                } else {
                  setData({
                    _id: data._id,
                    customerId: data?.customer?._id,

                    description: data.description,
                    group: data.group,
                    quantity: data.quantity,
                    rate: data.rate,
                    gst: data.gst,
                    totalAmount: data.totalAmount,
                  });
                }
              }}
              data={[...(customer?.records ?? {})]?.reverse()}
              columns={[
                {
                  title: "Date",
                  dataIndex: "date",
                },
                {
                  title: "Customer",
                  dataIndex: "customer",
                  render: (item) => {
                    return item.customer.name;
                  },
                },
                {
                  title: "description",
                  dataIndex: "description",
                  render: (item) => {
                    return `${item.description} ${
                      item.dispatchNote ? `,${item.dispatchNote}` : ""
                    }`;
                  },
                },
                {
                  title: "Items",
                  dataIndex: "group",
                },
                {
                  title: "Qnt.",
                  dataIndex: "quantity",
                },
                {
                  title: "Rate",
                  dataIndex: "rate",
                },
                {
                  title: "GST",
                  dataIndex: "gst",
                },
                {
                  title: "Total",
                  dataIndex: "totalAmount",
                },
              ]}
            />
          </div>
        )}
      </div>
      <CustomModal
        open={createInvoice}
        setOpen={() => {
          setCreateInvoice(false);
          setPartialQuantity("");
        }}
        button={
          <div className="flex gap-6">
            <Button
              variant="contained"
              color="primary"
              disabled={invoiceLoading}
              onClick={() => invoiceHandler(true)}
            >
              {invoiceLoading ? "Loading..." : "Save and Print"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => invoiceHandler(false)}
              disabled={invoiceLoading}
            >
              {invoiceLoading ? "Loading..." : "Save"}
            </Button>
          </div>
        }
        loading={invoiceLoading}
      >
        <InvoiceForm
          isPartialInvoice={isPartialInvoice}
          setIsPartialInvoice={setIsPartialInvoice}
          invoiceNo={invoiceNo}
          setInvoiceNo={setInvoiceNo}
          invoiceDate={invoiceDate}
          setInvoiceDate={setInvoiceDate}
          setInvoiceData={setInvoiceData}
          invoiceData={invoiceData}
          productList={productList}
          setOrderIds={setOrderIds}
          partialQuantity={partialQuantity}
          setPartialQuantity={setPartialQuantity}
          setProduct={setProduct}
        />
      </CustomModal>
    </Layout>
  );
};

export default Customer;
