import React, { useState } from "react";

import Search from "../../components/Search";

import {
  axiosinstance,
  getAllCustomerGroups,
  getAllCustomers,
  getWagon,
} from "../../utils/api";

import { toast } from "react-toastify";
import Layout4 from "../../components/Layout/Layout4";
import Loading from "../../components/Loading";
import Table from "../../components/Table";
import ClientModal from "../../components/modals/ClientModal";

const DispatchCustomerADD = () => {
  const [open, setOpen] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [groups, setGroups] = useState([]);
  const getAllCustomerHandler = async () => {
    setLoading(true);
    const [success, data] = await getAllCustomers();
    if (success) {
      setCustomers(
        data.map((item) => ({
          ...item,
          name: `${item.userName} ${
            item?.group?.name !== undefined ? `(${item?.group?.name})` : ""
          }`,
        }))
      );
    }
    setLoading(false);
  };

  const getAllCustomerGroupsHandler = async () => {
    setLoading(true);
    const [success, data] = await getAllCustomerGroups();
    if (success) {
      setGroups(data);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getAllCustomerHandler();
    getAllCustomerGroupsHandler();
  }, []);

  return (
    <Layout4
      type="Dispatch"
      onClick={() => {
        setOpen(true);
      }}
    >
      {loading && <Loading />}
      <div className="flex gap-3 flex-col md:flex-row">
        <Search
          data={customers.filter((item) => item?.type === "Customer")}
          item={(item) => {
            setSelected(item);
          }}
          active={selected?._id}
        />
        {selected && (
          <EditClient
            item={selected}
            reload={getAllCustomerHandler}
            setItem={setSelected}
            customers={customers}
            groups={groups}
          />
        )}
      </div>

      <ClientModal
        open={open}
        setOpen={setOpen}
        reload={getAllCustomerHandler}
        customers={customers}
        groups={groups}
        customer
      />
    </Layout4>
  );
};

export default DispatchCustomerADD;

const EditClient = ({ item, reload, setItem, customers, groups }) => {
  const [edit, setEdit] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [wagon, setWagon] = React.useState(0);
  const [showHistory, setShowHistory] = React.useState(false);
  const [history, setHistory] = React.useState([]);
  const [historyLoading, setHistoryLoading] = React.useState(false);

  const getAllWagon = async () => {
    const [success, data] = await getWagon(item?._id);
    if (success) {
      setWagon(data?.wagon);
    }
  };

  const fetchHistory = async () => {
    setHistoryLoading(true);
    try {
      const response = await axiosinstance(
        `/admin/customer-history/${item?._id}`
      );
      setHistory(response.data);
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setHistoryLoading(false);
  };

  React.useEffect(() => {
    getAllWagon();
  }, [item?._id]);

  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col gap-3">
        <Table
          data={[item]}
          columns={[
            {
              title: "Username",
              dataIndex: "userName",
            },
            {
              title: "Phone Number",
              dataIndex: "phoneNumber",
            },
            {
              title: "Image",
              dataIndex: "image",
              render: (item) => (
                <a href={item.image} target="_blank" rel="noreferrer">
                  {item.image ? "View" : ""}
                </a>
              ),
            },
            {
              title: "Action",
              render: (item) => {
                return (
                  <div className="flex gap-2">
                    <button
                      onClick={() => {
                        setItem({
                          ...item,
                          wagon: wagon,
                        });
                        setEdit(true);
                      }}
                      className="border mr-2 border-black px-4 py-1 bg-yellow-400 text-xl "
                    >
                      Edit
                    </button>
                  </div>
                );
              },
            },
          ]}
        />
      </div>

      <ClientModal
        open={edit}
        setOpen={setEdit}
        reload={reload}
        update={item}
        customers={customers}
        setUpdate={setItem}
        staff={"dispatch"}
        customer
        groups={groups}
      />
    </div>
  );
};
