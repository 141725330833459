import React, { useEffect } from "react";

import { Button, CircularProgress } from "@mui/material";
import { GrFormClose } from "react-icons/gr";
import { toast } from "react-toastify";
import Dash from "../../components/Dash";
import Layout from "../../components/Layout/Layout";
import Loading from "../../components/Loading";
import CustomModal, { ModalInput, ModalSelect } from "../../components/Modal";
import Search from "../../components/Search";
import CustomSelect from "../../components/Select";
import Success from "../../components/Success";
import Table from "../../components/Table";
import DeleteModal from "../../components/modals/DeleteModal";
import {
  createPaymentadmin,
  createStaff,
  createStaffSalary,
  deletePayment,
  deleteSalary,
  deleteStaff,
  getAllStaff,
  getstaffPayment,
  getstaffSalary,
  getstaffbalance,
  getstaffbyid,
  getstaffrecevied,
  updatePayment,
  updateStaff,
  updateStaffSalary,
  updateStaffpayment,
} from "../../utils/api";
import useStore from "../../utils/store";
const Staff = () => {
  const [open, setOpen] = React.useState(false);
  const startDate = useStore((state) => state.startDate);
  const endDate = useStore((state) => state.endDate);
  const user = useStore((state) => state.user);
  const [staff, setStaff] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [newStaff, setNewStaff] = React.useState(null);
  const [selected, setSelected] = React.useState(null);
  const initialPayment = {
    staffId: "",
    description: "",
    amount: "",
    paymentType: "",
    date: new Date(),
    completedById: user?.admin?._id,
  };
  const [newPayment, setNewPayment] = React.useState(initialPayment);
  const getStaff = async () => {
    setLoading(true);
    const [success, data] = await getAllStaff(startDate, endDate);
    if (success) {
      setStaff(data);
    }
    setLoading(false);
  };

  const addPaymentHandler = async () => {
    if (
      newPayment.staffId === "" ||
      newPayment.amount === "" ||
      newPayment.paymentType === ""
    ) {
      toast.error("Please fill all the fields");
      return;
    }
    setLoading(true);
    const [success, data] =
      newPayment.paymentType === "Salary"
        ? await createStaffSalary(newPayment)
        : await createPaymentadmin(newPayment);
    if (success) {
      setNewPayment(initialPayment);
      toast.success("Payment added successfully");
    } else {
      toast.error(data);
    }
    setLoading(false);
  };

  const createNewStaff = async () => {
    if (!newStaff.userName || !newStaff.password || !newStaff.role) {
      alert("Please fill all the fields");
      return;
    }
    setLoading(true);
    const [success, data] = await createStaff(newStaff);
    if (success) {
      setOpen(false);
      setNewStaff({});
      setSuccess(true);
      getStaff();
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getStaff();
  }, [startDate, endDate]);

  return (
    <Layout
      text="Add New Staff"
      onClick={() => {
        setOpen(true);
      }}
    >
      {loading && <Loading />}
      <div className="flex gap-3 flex-col md:flex-row md:w-[calc(100%-15rem)]">
        <Search
          data={staff.map((item) => ({
            ...item,
            name: `${item.userName}`,
          }))}
          item={(item) => {
            setSelected(item);
          }}
          active={selected?._id}
        />
        {selected ? (
          <EditStaff item={selected} reload={getStaff} setItem={setSelected} />
        ) : (
          <div>
            <div className="flex md:items-center justify-center gap-3 md:flex-row flex-col ">
              <div className="flex flex-col gap-2">
                <div className="bg-secondary border-2 border-black px-2 py-1">
                  Staff
                </div>
                <CustomSelect
                  onChange={(e) => {
                    setNewPayment({
                      ...newPayment,
                      staffId: e.target.value,
                    });
                  }}
                  options={staff.map((customer) => ({
                    value: customer._id,
                    label: `${customer.userName} `,
                  }))}
                  value={newPayment.staffId}
                />
              </div>
              <div className="flex flex-col gap-2">
                <div className="bg-secondary border-2 border-black px-2 py-1">
                  description
                </div>
                <ModalInput
                  width={"w-full"}
                  value={newPayment?.description}
                  onChange={(e) => {
                    setNewPayment({
                      ...newPayment,
                      description: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="flex flex-col gap-2">
                <div className="bg-secondary border-2 border-black px-2 py-1">
                  Payment
                </div>
                <CustomSelect
                  onChange={(e) => {
                    setNewPayment({
                      ...newPayment,
                      paymentType: e.target.value,
                    });
                  }}
                  options={[
                    { value: "Received", label: "Payment" },
                    { value: "Payment", label: "Received" },
                    { value: "Salary", label: "Salary" },
                  ]}
                  value={newPayment.paymentType}
                />
              </div>
              <div className="flex flex-col gap-2">
                <div className="bg-secondary border-2 border-black px-2 py-1">
                  Amount
                </div>
                <ModalInput
                  width={"w-full"}
                  value={newPayment?.amount}
                  onChange={(e) => {
                    setNewPayment({ ...newPayment, amount: e.target.value });
                  }}
                  type={"number"}
                />
              </div>
              <div className="flex flex-col gap-2">
                <div className="bg-secondary border-2 border-black px-2 py-1">
                  Date
                </div>
                <ModalInput
                  width={"w-full"}
                  value={newPayment?.date}
                  onChange={(e) => {
                    setNewPayment({ ...newPayment, date: e.target.value });
                  }}
                  type={"date"}
                />
              </div>

              <button
                onClick={addPaymentHandler}
                className="border-2 w-full md:w-auto border-black bg-primary text-white py-1 px-2 focus:outline-none"
              >
                Save
              </button>
            </div>
          </div>
        )}
      </div>
      <CustomModal
        open={open}
        setOpen={setOpen}
        onClick={createNewStaff}
        loading={loading}
      >
        <ModalInput
          title="USERNAME"
          onChange={(e) => {
            setNewStaff((prev) => ({ ...prev, userName: e.target.value }));
          }}
        />

        <ModalInput
          title="PASSWORD"
          onChange={(e) => {
            setNewStaff((prev) => ({ ...prev, password: e.target.value }));
          }}
        />
        <ModalSelect
          onChange={(e) => {
            setNewStaff((prev) => ({ ...prev, role: e.target.value }));
          }}
          title="Group"
          options={[
            "TradingStaff",
            "MarketingStaff",
            "StancilStaff",
            "OtherStaff",
            "TagStaff",
            "Dispatch",
          ]}
        />
      </CustomModal>
      <Success open={success} setOpen={setSuccess} />
    </Layout>
  );
};

export default Staff;

const EditStaff = ({ item, reload, setItem }) => {
  const [edit, setEdit] = React.useState(false);
  const [edit2, setEdit2] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [newStaff, setNewStaff] = React.useState({
    password: item?.password,
    role: item?.role,
  });
  const startDate = useStore((state) => state.startDate);
  const endDate = useStore((state) => state.endDate);
  const [staff, setStaff] = React.useState(null);
  const [data, setData] = React.useState(null);

  const getStaff = async () => {
    setLoading(true);
    const [success, data] = await getstaffbyid(item?._id, startDate, endDate);
    if (success) {
      setStaff(data);
    }
    setLoading(false);
  };

  const updateStaffHandler = async () => {
    setLoading(true);
    const [success, data] = await updateStaff(item?._id, newStaff);
    if (success) {
      setItem({
        ...item,
        ...newStaff,
      });

      reload();
      toast.success("Updated");
    }
    setLoading(false);
  };

  useEffect(() => {
    getStaff();
  }, [item, startDate, endDate]);

  return (
    <div className="flex flex-col gap-10 w-full">
      <div className="flex gap-2 flex-col md:flex-row">
        <div className="flex flex-col gap-2">
          <div className="border bg-yellow-400 border-black px-2 py-1 ">
            USERNAME
          </div>
          <div className="border border-black px-2 py-1 ">{item?.userName}</div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="border bg-yellow-400 border-black px-2 py-1 ">
            TAG
          </div>
          <div className="border border-black px-2 py-1 ">{item?.role}</div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="border bg-yellow-400 border-black px-2 py-1 w-fit ">
            Actions
          </div>
          <div className="flex gap-2">
            <button
              onClick={() => {
                setEdit(!edit);
              }}
              className="border border-black px-4 py-1 bg-yellow-400 text-xl "
            >
              Edit
            </button>
            <button
              onClick={async () => {
                setLoading(true);
                const [success, data] = await deleteStaff(item?._id);
                if (success) {
                  setItem(null);
                  reload();
                  toast.success("Deleted");
                }
                setLoading(false);
              }}
              className="border border-black  py-1 bg-red-600 px-4 text-xl "
            >
              {loading ? "Loading..." : "Delete"}
            </button>
          </div>
        </div>
      </div>
      {staff && (
        <div className="flex-1 flex flex-col w-full">
          <div className="flex-1  flex-col md:flex-row flex gap-6">
            <button
              onClick={async () => {
                const [success, data] = await getstaffrecevied(
                  item?._id,
                  startDate,
                  endDate
                );
                if (success) {
                  setData(data);
                }
              }}
              className="flex-1"
            >
              <Dash
                title={"Received"}
                value={staff?.record?.received?.toFixed(2)}
              />
            </button>
            <button
              onClick={async () => {
                const [success, data] = await getstaffPayment(
                  item?._id,
                  startDate,
                  endDate
                );
                if (success) {
                  setData(data);
                }
              }}
              className="flex-1"
            >
              <Dash
                title={"Payment"}
                value={staff?.record?.payment?.toFixed(2)}
              />
            </button>
            <button
              onClick={async () => {
                const [success, data] = await getstaffbalance(
                  item?._id,
                  startDate,
                  endDate
                );
                if (success) {
                  setData(data.reverse());
                }
              }}
              className="flex-1"
            >
              <Dash
                title={"Balance"}
                value={
                  staff?.staff?.role === "MarketingStaff"
                    ? staff?.staffBalance?.toFixed(2)
                    : staff?.record?.balance?.toFixed(2)
                }
              />
            </button>
            <button
              onClick={async () => {
                const [success, data] = await getstaffSalary(
                  item?._id,
                  startDate,
                  endDate
                );
                if (success) {
                  setData(data);
                }
              }}
              className="flex-1"
            >
              <Dash title={"Salary"} value={staff?.salary?.toFixed(2)} />
            </button>
          </div>
          {data && (
            <div className="mt-3">
              <h1 className="text-2xl font-semibold">
                {data?.[0]?.paymentType
                  ? data?.[0]?.paymentType
                  : "Purchase Items"}
              </h1>
              {data?.[0]?.paymentType ? (
                <Table
                  data={[...data]?.reverse()}
                  columns={[
                    {
                      title: "Date",
                      dataIndex: "date",
                      render(item) {
                        return new Date(item?.date)
                          ?.toISOString()
                          ?.replace(/T.*/, "")
                          ?.split("-")
                          ?.reverse()
                          ?.join("-");
                      },
                    },
                    {
                      title: "Customer",
                      accessor: "customer",
                      render(item) {
                        return item?.customer?.userName;
                      },
                    },

                    {
                      title: "Description",
                      dataIndex: "description",
                    },
                    {
                      title: "Location",
                      dataIndex: "location",
                      render(item) {
                        return item?.customer?.group?.name;
                      },
                    },

                    {
                      title: "Type",
                      dataIndex: "paymentType",
                    },
                    {
                      title: "Total",
                      dataIndex: "amount",
                      render(item) {
                        return item?.amount?.toFixed(2);
                      },
                    },
                    {
                      title: "Completed By",
                      dataIndex: "completedByName",
                    },

                    {
                      title: "Actions",
                      dataIndex: "actions",
                      render(item) {
                        return (
                          <div className="flex flex-col gap-2">
                            <div className="flex gap-2 ">
                              <button
                                onClick={() => {
                                  setOpen(true);
                                  setEdit2(item);
                                }}
                                className="border border-black px-4 py-1 bg-yellow-400 text-xl "
                              >
                                Edit
                              </button>
                              <DeleteModal
                                onClick={async () => {
                                  setLoading(true);
                                  const [success, data] =
                                    item?.paymentType === "Salary"
                                      ? await deleteSalary(item?._id)
                                      : await deletePayment(item?._id);
                                  if (success) {
                                    setData(null);
                                    getStaff();
                                    toast.success("Deleted");
                                  }
                                  setLoading(false);
                                }}
                                loading={loading}
                              />
                              {/* <button
                              
                                className="border border-black  py-1 bg-red-600 px-4 text-xl "
                              >
                                {loading ? "Loading..." : "Delete"}
                              </button> */}
                            </div>
                          </div>
                        );
                      },
                    },
                    {
                      title: "PDF",
                      link: `/pdf/admin/staff/${data?.[0]?.paymentType}/${item?._id}`,
                    },
                  ]}
                />
              ) : (
                <Table
                  data={data?.reverse()}
                  columns={[
                    {
                      title: "Date",
                      dataIndex: "date",
                      render(item) {
                        return new Date(item?.date)
                          ?.toISOString()
                          ?.replace(/T.*/, "")
                          ?.split("-")
                          ?.reverse()
                          ?.join("-");
                      },
                    },
                    {
                      title: "Customer",
                      accessor: "customer",
                      render(item) {
                        return item?.customer?.userName;
                      },
                    },

                    {
                      title: "Description",
                      dataIndex: "description",
                    },
                    {
                      title: "Location",
                      dataIndex: "location",
                      render(item) {
                        return item?.customer?.group?.name;
                      },
                    },
                    {
                      title: "Product Name",
                      dataIndex: "location",
                      render(item) {
                        return item?.product?.productName;
                      },
                    },
                    {
                      title: "Qnt",
                      dataIndex: "quantity",
                    },
                    {
                      title: "Rate",
                      dataIndex: "rate",
                    },
                    {
                      title: "Gst",
                      dataIndex: "gst",
                    },
                    {
                      title: "Total",
                      dataIndex: "totalAmount",
                      render(item) {
                        return item?.totalAmount?.toFixed(2);
                      },
                    },
                    {
                      title: "User",
                      dataIndex: "completedByName",
                    },

                    {
                      title: "PDF",
                      link: `/pdf/admin/staff/Balance/${item?._id}`,
                    },
                  ]}
                />
              )}
            </div>
          )}
        </div>
      )}
      {edit && (
        <div className="flex flex-col gap-6 items-start border bg-secondary border-black p-5 ">
          <button onClick={() => setEdit(false)} className="ml-auto ">
            <GrFormClose className="text-3xl " />
          </button>

          <div className="flex  gap-2">
            <div className="flex flex-col  gap-2">
              <div className="border bg-yellow-400 border-black px-2 py-1 ">
                USERNAME
              </div>
              <div className="border border-black px-2 py-1 ">
                {item?.userName}
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <ModalInput
              bg={"bg-white"}
              title="PASSWORD"
              onChange={(e) => {
                setNewStaff((prev) => ({
                  ...prev,
                  password: e.target.value,
                }));
              }}
            />
            <ModalSelect
              bg={"bg-white"}
              onChange={(e) => {
                setNewStaff((prev) => ({ ...prev, role: e.target.value }));
              }}
              value={newStaff?.role}
              title="Group"
              options={[
                "TradingStaff",
                "MarketingStaff",
                "StancilStaff",
                "OtherStaff",
                "TagStaff",
                "Dispatch",
              ]}
            />
          </div>
          <Button
            disabled={loading}
            variant="contained"
            color="primary"
            onClick={updateStaffHandler}
          >
            {loading ? <CircularProgress size={20} /> : "Update"}
          </Button>
        </div>
      )}

      <CustomModal
        open={open}
        setOpen={setOpen}
        onClick={async () => {
          const [success, data] =
            edit2.paymentType === "Salary"
              ? await updateStaffSalary(edit2?._id, edit2)
              : edit2.paymenFlow === "STC"
              ? await updatePayment(edit2?._id, edit2)
              : await updateStaffpayment(edit2?._id, edit2);
          if (success) {
            setEdit2(null);
            setOpen(false);
            setData(null);
            getStaff();
            toast.success("Updated Staff");
          }
        }}
      >
        <ModalInput
          title={"Description"}
          value={edit2?.description}
          onChange={(e) => {
            setEdit2({
              ...edit2,
              description: e.target.value,
            });
          }}
        />

        <ModalInput
          title={"Amount"}
          value={edit2?.amount}
          onChange={(e) => {
            setEdit2({
              ...edit2,
              amount: e.target.value,
            });
          }}
        />
        <div className="flex items-center justify-between gap-2">
          <div className="">Payment</div>
          <CustomSelect
            width={"255px"}
            onChange={(e) => {
              setEdit2({
                ...edit2,
                paymentType: e.target.value,
              });
            }}
            options={[
              { value: "Received", label: "Received" },
              { value: "Payment", label: "Payment" },
              { value: "Salary", label: "Salary" },
            ]}
            value={edit2?.paymentType}
            disabled={edit2?.paymentType === "Salary"}
          />
        </div>
      </CustomModal>
    </div>
  );
};
