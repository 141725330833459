import { Checkbox } from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { createCustomer, updateCustomer } from "../../utils/api";
import useStore from "../../utils/store";
import CustomModal, { ModalInput } from "../Modal";
import SearchableMultiSelect from "./CustomerSearchSelect";

const ClientModal = ({
  open,
  setOpen,
  reload,
  customers,
  update,
  setUpdate,
  groups,
  customer,
  staff,
}) => {
  const [newCustomer, setNewCustomer] = useState({
    access: [],
  });

  const user = useStore((state) => state.user);
  console.log(user);

  const [loading, setLoading] = useState(false);
  const createNewCustomer = async () => {
    if (
      !newCustomer.userName ||
      (!newCustomer.password && !customer) ||
      !newCustomer.phoneNumber
    ) {
      toast.error("Please Fill All Fields");
      return;
    }

    if (newCustomer.gstNumber && newCustomer.gstNumber.length !== 15) {
      toast.error("GST Number must be 15 digits if provided");
      return;
    }

    const formdata = new FormData();
    setLoading(true);
    if (!update) {
      if (customer) {
        delete newCustomer.password;
        newCustomer.type = "Customer";
        formdata.append("userName", newCustomer.userName);
        formdata.append("phoneNumber", newCustomer.phoneNumber);
        formdata.append("group", newCustomer.group);
        formdata.append("type", "Customer");
        formdata.append("image", newCustomer.image);
        formdata.append("wagon", newCustomer.wagon);
        if (newCustomer.address) {
          formdata.append("address", newCustomer.address);
        }
        if (newCustomer.location) {
          formdata.append("location", newCustomer.location);
        }
        if (newCustomer.gstNumber) {
          formdata.append("gstNumber", newCustomer.gstNumber);
        }
        if (newCustomer.fssai) {
          formdata.append("fssai", newCustomer.fssai);
        }
        if (newCustomer.agency) {
          formdata.append("agency", newCustomer.agency);
        }
      } else {
        newCustomer.type = "Client";
        newCustomer.orderAccess = newCustomer.orderAccess ? true : false;
      }
      const [success, data] = await createCustomer(
        customer ? formdata : newCustomer
      );
      if (success) {
        setOpen(false);
        setNewCustomer({});
        reload();
        toast.success("Customer Created Successfully");
      } else {
        toast.error(data.response.data.message);
      }
    } else {
      const isAdmin = user?.admin?.role === "Admin";
      formdata.append(
        "updatedById",
        isAdmin ? user?.admin?._id : user?.staff?._id
      );
      formdata.append("type", isAdmin ? "admin" : "staff");
      if (customer) {
        formdata.append("userName", newCustomer.userName);
        formdata.append("phoneNumber", newCustomer.phoneNumber);
        // formdata.append("type", "Customer");
        formdata.append("image", newCustomer.image);
        formdata.append("wagon", newCustomer.wagon);
        formdata.append("group", newCustomer.group);
        if (newCustomer.address) {
          formdata.append("address", newCustomer.address);
        }
        if (newCustomer.location) {
          formdata.append("location", newCustomer.location);
        }
        if (newCustomer.gstNumber) {
          formdata.append("gstNumber", newCustomer.gstNumber);
        }
        if (newCustomer.fssai) {
          formdata.append("fssai", newCustomer.fssai);
        }
        if (newCustomer.agency) {
          formdata.append("agency", newCustomer.agency);
        }
      } else {
        formdata.append("phoneNumber", newCustomer.phoneNumber);
        formdata.append("password", newCustomer.password);
        formdata.append(
          "access",
          JSON.stringify(newCustomer.access?.map((item) => item._id))
        );
        formdata.append("orderAccess", newCustomer.orderAccess);
        // formdata.append("type", "Client");
      }

      const [success, data] = await updateCustomer(update._id, formdata);
      if (success) {
        setOpen(false);
        setUpdate(null);
        reload();
        toast.success("Customer Updated Successfully");
      }
    }
    setLoading(false);
  };
  console.log(update);

  React.useEffect(() => {
    if (update) {
      setNewCustomer({
        ...update,
        access: update.access,
        group: update?.group?._id,
      });
    }
  }, [update]);

  console.log(!staff, staff);

  return (
    <CustomModal
      open={open}
      setOpen={setOpen}
      onClick={createNewCustomer}
      loading={loading}
    >
      {!staff && (
        <>
          <ModalInput
            title={"USERNAME"}
            onChange={(e) => {
              setNewCustomer({ ...newCustomer, userName: e.target.value });
            }}
            value={newCustomer.userName}
          />
          {customer && (
            <ModalInput
              title={"ADDRESS"}
              onChange={(e) => {
                setNewCustomer({ ...newCustomer, address: e.target.value });
              }}
              value={newCustomer.address}
            />
          )}

          {customer && (
            <ModalInput
              title={"GSTIN"}
              onChange={(e) => {
                setNewCustomer({ ...newCustomer, gstNumber: e.target.value });
              }}
              value={newCustomer.gstNumber}
            />
          )}
          {!customer && (
            <ModalInput
              title={"PASSWORD"}
              onChange={(e) => {
                setNewCustomer({ ...newCustomer, password: e.target.value });
              }}
              value={newCustomer.password}
            />
          )}
          <ModalInput
            title={"Phone Number"}
            onChange={(e) => {
              setNewCustomer({ ...newCustomer, phoneNumber: e.target.value });
            }}
            value={newCustomer.phoneNumber}
          />
        </>
      )}

      {customer && (
        <ModalInput
          title={"LOCATION"}
          onChange={(e) => {
            setNewCustomer({ ...newCustomer, location: e.target.value });
          }}
          value={newCustomer.location}
        />
      )}
      {staff !== "dispatch" && (
        <div className="flex gap-1 md:gap-3 flex-col md:flex-row md:items-center justify-between">
          <label htmlFor={"Group"}>City</label>
          <select
            value={newCustomer.group}
            name={"Group"}
            id={"Group"}
            className={`
       bg-transparent
        border-2 border-black md:w-64 py-1 `}
            onChange={(e) => {
              setNewCustomer({ ...newCustomer, group: e.target.value });
            }}
          >
            <option value="">Select</option>
            {groups?.map((item) => (
              <option key={item._id} value={item._id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      )}

      {customer && (
        <>
          {staff !== "dispatch" && (
            <>
              <ModalInput
                type="file"
                title={"Image"}
                onChange={(e) => {
                  setNewCustomer({ ...newCustomer, image: e.target.files[0] });
                }}
              />
              <ModalInput
                type="number"
                title={"Wagon"}
                onChange={(e) => {
                  setNewCustomer({ ...newCustomer, wagon: e.target.value });
                }}
                value={newCustomer.wagon}
              />
              <ModalInput
                type="text"
                title={"FSSAI"}
                value={newCustomer.fssai}
                onChange={(e) => {
                  setNewCustomer({ ...newCustomer, fssai: e.target.value });
                }}
              />
              <ModalInput
                type="text"
                title={"Agency"}
                value={newCustomer.agency}
                onChange={(e) => {
                  setNewCustomer({ ...newCustomer, agency: e.target.value });
                }}
              />
            </>
          )}
        </>
      )}

      {!customer && (
        <>
          <SearchableMultiSelect
            value={newCustomer.access}
            onChange={(e) => {
              setNewCustomer({ ...newCustomer, access: e });
            }}
          />
          <div className="flex gap-1 md:gap-3 sitems-center flex-row justify-between ">
            <label htmlFor={"Order Access"}>Order Access</label>

            <Checkbox
              checked={newCustomer.orderAccess}
              onChange={(e) => {
                setNewCustomer({
                  ...newCustomer,
                  orderAccess: e.target.checked,
                });
              }}
            />
          </div>
        </>
      )}
    </CustomModal>
  );
};

export default ClientModal;
