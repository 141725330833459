import React from "react";
import { toast } from "react-toastify";
import Layout4 from "../../components/Layout/Layout4";
import Loading from "../../components/Loading";
import Table from "../../components/Table";
import { completeOrder, getAllTagOrders } from "../../utils/api";
import { formatDate } from "../../utils/formatData";
import useStore from "../../utils/store";

const TagStaff = () => {
  const [dash, setDash] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const getData = async () => {
    setLoading(true);
    const [success, data] = await getAllTagOrders();
    if (success) {
      setDash(data);
    }
    setLoading(false);
  };
  const user = useStore((state) => state.user);
  React.useEffect(() => {
    getData();
  }, []);
  return (
    <Layout4 type={"TagStaff"} title={user?.staff?.userName}>
      {loading && <Loading />}

      <Table
        scroll={false}
        type="tag"
        data={dash}
        columns={[
          {
            title: "Date",
            render: (row) => formatDate(row?.createdAt),
          },
          {
            title: "User",
            render: (row) => (
              <div>
                {row.createdByName}, {row?.completedByName}
              </div>
            ),
          },
          {
            title: "FSSAI",
            render: (record) => record?.customer?.fssai,
          },
          {
            title: "Agency",
            render: (record) => record?.customer?.agency,
          },
          {
            title: "description",
            render: (row) => row.description,
          },

          {
            title: "Items",
            render: (row) => row.product.productName,
          },
          {
            title: "Qnt.",
            render: (row, index) => {
              console.log(index, dash, row);
              return (
                <div className="relative">
                  {row.quantity}

                  {
                    //only last
                    index === dash.length - 1 && (
                      <div className="absolute -right-2 -bottom-12 z-[1] bg-primary text-white px-2 py-1 rounded-md ">
                        <p>
                          Total Qnt.{" "}
                          {dash?.reduce((acc, item) => acc + item.quantity, 0)}
                        </p>
                      </div>
                    )
                  }
                </div>
              );
            },
          },
          {
            title: "Customer",
            render: (row) => row.customer.userName,
          },
          {
            title: "Location",
            render: (record) => record?.customer?.group?.name,
          },
          {
            title: "Image",
            render: (row) => (
              <a href={row?.customer?.image} target="_blank" rel="noreferrer">
                {row?.customer?.image ? "View" : ""}
              </a>
            ),
          },
          {
            title: "PDF",
            render: (row) => (
              <button
                className="bg-green-500  px-2 py-1 "
                onClick={async () => {
                  setLoading(true);
                  const [success, data] = await completeOrder(row._id, {
                    type: "staff",
                    completedById: user.staff._id,
                  });
                  if (success) {
                    toast.success("Order Completed");
                    getData();
                  } else {
                    toast.error(data);
                  }
                  setLoading(false);
                }}
              >
                Complete
              </button>
            ),
          },
        ]}
      />
    </Layout4>
  );
};

export default TagStaff;
