import React, { useState } from "react";

import { getAllCustomerGroups, getAllCustomers } from "../../../utils/api";

import Layout3 from "../../../components/Layout/Layout3";
import Loading from "../../../components/Loading";
import ClientModal from "../../../components/modals/ClientModal";
import useStore from "../../../utils/store";

const MarketADDCustomer = () => {
  const [open, setOpen] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);

  const [groups, setGroups] = useState([]);
  const user = useStore((state) => state.user);
  const getAllCustomerHandler = async () => {
    setLoading(true);
    const [success, data] = await getAllCustomers();
    if (success) {
      setCustomers(
        data.map((item) => ({
          ...item,
          name: `${item.userName} ${
            item?.group?.name !== undefined ? `(${item?.group?.name})` : ""
          }`,
        }))
      );
    }
    setLoading(false);
  };

  const getAllCustomerGroupsHandler = async () => {
    setLoading(true);
    const [success, data] = await getAllCustomerGroups();
    if (success) {
      setGroups(data);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getAllCustomerHandler();
    getAllCustomerGroupsHandler();
  }, []);

  return (
    <Layout3
      title={user.staff.userName}
      text="Add New Customer"
      onClick={() => {
        setOpen(true);
      }}
    >
      {loading && <Loading />}

      <ClientModal
        open={open}
        setOpen={setOpen}
        reload={getAllCustomerHandler}
        customers={customers}
        groups={groups}
        customer
      />
    </Layout3>
  );
};

export default MarketADDCustomer;
