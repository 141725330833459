import React from "react";
import Loading from "../../components/Loading";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
import {
  Document,
  Font,
  PDFViewer,
  Page,
  Text,
  View,
  pdf,
} from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { useParams } from "react-router-dom";
import WorkBold from "../../assets/fonts/WorkSans-Bold.ttf";
import WorkBoldItalic from "../../assets/fonts/WorkSans-BoldItalic.ttf";
import WorkRegular from "../../assets/fonts/WorkSans-Regular.ttf";
import WorkSemiBold from "../../assets/fonts/WorkSans-SemiBold.ttf";
import Logo from "../../components/invoices/Logo";
import Table from "../../components/invoices/Table";
import { getInvoiceById } from "../../utils/api";
import { styles } from "../../utils/invoiceStyles";
import useStore from "../../utils/store";
Font.register({
  family: "WorkSans",
  src: WorkRegular,

  fonts: [
    { src: WorkRegular },
    { src: WorkBold, fontWeight: "bold" },
    { src: WorkBoldItalic, fontWeight: "bold", fontStyle: "italic" },
    { src: WorkSemiBold, fontWeight: "semibold" },
  ],
});

const InvoicePage = ({ type, invoice }) => {
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.main}>
        <View
          style={{
            borderBottom: "solid",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-evenly",
            flexDirection: "row",
            borderBottomWidth: 1,
            padding: 10,
          }}
        >
          <Logo />

          <View
            style={{
              fontWeight: "bold",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
            }}
          >
            <Text
              style={{
                fontWeight: "bold",
                textDecoration: "underline",
                fontSize: "12px",
              }}
            >
              TAX INVOICE
            </Text>
            <Text style={{ fontSize: "18px", fontWeight: "heavy" }}>
              PRABHU LABELS
            </Text>
            <Text style={{ fontSize: "10px" }}>
              D-298 Shaheed Bhagat Singh Nagar, Dhandra Road, Ludhiana
            </Text>
            <Text
              style={{
                fontWeight: "bold",
                textDecoration: "underline",
                fontSize: "12px",
              }}
            >
              GSTIN : 03ABLPY4996M1ZM
            </Text>
            <Text
              style={{
                fontSize: "10px",
                fontStyle: "italic",
                fontWeight: "semibold",
              }}
            >
              Tel: 9417771111 , email: info@prabhulabels.com
            </Text>
            <Text style={{ fontSize: "10px" }}>
              Mfrs Of :- Non Woven Printed Tag, Plain &amp; Printed Barcode
              Labels
            </Text>
          </View>
          <Text
            style={{
              fontWeight: "bold",
              fontSize: "12px",
              fontStyle: "italic",
            }}
          >
            {type}
          </Text>
        </View>

        {/* tax invoice */}

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "flex-start",
            width: "100%",
            borderBottom: "solid",
            fontWeight: "bold",
            borderBottomWidth: 1,
          }}
        >
          <View
            style={{
              textAlign: "start",

              width: "50%",
              borderRight: "solid",
              borderRightWidth: 1,
              padding: 8,
            }}
          >
            <View>
              <Text
                style={{
                  fontWeight: 600,
                  fontSize: "10px",
                  fontStyle: "italic",
                }}
              >
                Party Details:{" "}
              </Text>
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                {invoice?.customer?.userName}
              </Text>

              {/* {  invoice?.customer?.address} */}
              {invoice?.customer?.address.split("\\n").map((i, key) => {
                return (
                  <Text
                    key={key}
                    style={{
                      fontWeight: "semibold",
                      fontSize: "10px",
                    }}
                  >
                    {i?.[0] === " " ? i.slice(1) : i}
                  </Text>
                );
              })}
            </View>

            <Text
              style={{
                marginTop: "50px",
                fontWeight: "heavy",
                fontSize: "12px",
              }}
            >
              GSTIN / UIN : {invoice?.customer?.gstNumber}
            </Text>
          </View>
          <View
            style={{
              textAlign: "start",
              padding: 8,
              width: "50%",
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
          >
            ?
            <Text
              style={{
                fontWeight: "bold",
                fontSize: "10px",
              }}
            >
              Invoice No. : {invoice?.invoiceNumberString}
            </Text>
            <Text
              style={{
                fontWeight: "bold",
                fontSize: "10px",
              }}
            >
              Dated :{" "}
              {new Date(invoice?.invoiceDate)
                .toLocaleDateString("en-GB")
                .replaceAll("/", "-")}
            </Text>
            {/* <div>Place of Supply : {invoice.customer.location}</div> */}
            <Text
              style={{
                fontWeight: "bold",
                fontSize: "10px",
              }}
            >
              Reverse Charge : N
            </Text>
          </View>
        </View>
        {/* second component */}
        <Table invoice={invoice} />
        {/* total amount component */}

        <View
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 30,
            padding: 10,
            borderBottom: "solid",
            borderBottomWidth: 1,
          }}
        >
          <View style={styles.row}>
            <View style={styles.column}>
              <Text style={styles.text}> Taxable Amount</Text>
              <View style={styles.border} />
              <Text style={styles.text}>
                {" "}
                {invoice?.preGstTotal?.toFixed(2)}
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.text}> CGST Amount</Text>
              <View style={styles.border} />
              <Text style={styles.text}> {invoice?.cgst?.toFixed(2)}</Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.text}> SGST Amount</Text>
              <View style={styles.border} />
              <Text style={styles.text}> {invoice?.sgst?.toFixed(2)}</Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.text}> Total Tax</Text>
              <View style={styles.border} />
              <Text style={styles.text}> {invoice?.totalTax?.toFixed(2)}</Text>
            </View>
          </View>
          <Text
            style={{
              fontWeight: "bold",
              fontSize: "12px",
              textAlign: "left",
              textTransform: "capitalize",
            }}
          >
            Rupees {invoice?.amountInWords} Only
          </Text>
        </View>
        {/* taxs */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 6,
            padding: 10,
            borderBottom: "solid",
            borderBottomWidth: 1,
          }}
        >
          <Text
            style={{
              fontWeight: "bold",
              fontSize: "12px",
              textAlign: "left",
            }}
          >
            Bank Details:
          </Text>
          <View>
            <Text
              style={{
                fontWeight: "semibold",
                fontSize: "12px",
                textAlign: "left",
              }}
            >
              Bank- CANARA BANK, A/C-81531400000484
            </Text>
            <Text
              style={{
                fontWeight: "semibold",
                fontSize: "12px",
                textAlign: "left",
              }}
            >
              Ifsc Code - CNRB0002108, Branch: Millerganj, Gill Road
            </Text>
          </View>
        </View>
        {/* bank details */}

        <View
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <View
            style={{
              borderRight: "solid",
              borderRightWidth: 1,
              padding: 10,
              width: "40%",
            }}
          >
            <Text
              style={{
                fontWeight: "bold",
                fontSize: "12px",
                textAlign: "left",
              }}
            >
              Terms &amp; conditions
            </Text>
            <Text
              style={{
                fontSize: "10px",
                marginTop: "10px",
                textAlign: "left",
              }}
            >
              1. Goods once sold will not be taken back.
            </Text>
            <Text style={{ fontSize: "10px", textAlign: "left" }}>
              2. Interest @ 18%p.a. will be charged if payment is not made in
              the stipulated time.
            </Text>
            <Text style={{ fontSize: "10px", textAlign: "left" }}>
              3. Subject to 'Ludhiana' Juridction only.
            </Text>
          </View>
          <View style={{ width: "60%" }}>
            <View
              style={{
                borderBottom: "solid",
                borderBottomWidth: 1,
                padding: 10,
              }}
            >
              <Text
                style={{
                  fontWeight: "semibold",
                  fontSize: "10px",
                  textAlign: "left",
                  marginBottom: 30,
                }}
              >
                Receiver's Signature :
              </Text>
            </View>
            <View
              style={{
                padding: 10,
              }}
            >
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "right",
                  marginBottom: 30,
                }}
              >
                For PRABHU LABELS
              </Text>
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "right",
                }}
              >
                Authorised Signatory
              </Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

const MyDocument = ({ invoice }) => {
  return (
    <Document
      title={`Invoice-${invoice?.customer?.userName}-${invoice?.invoiceNumberString}`}
    >
      <InvoicePage invoice={invoice} type="Original For Buyer" />
      <InvoicePage invoice={invoice} type="Duplicate Copy" />
    </Document>
  );
};

const InvoiceConvertor = (id) => {
  const [invoice, setInvoice] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const pdfRef = React.useRef();
  const params = useParams();

  const startDate = useStore((state) => state.startDate);
  const endDate = useStore((state) => state.endDate);
  const getinvoice = async () => {
    setLoading(true);
    const [success, data] = await getInvoiceById(
      params.id ?? id,
      startDate,
      endDate
    );
    if (success) {
      setInvoice(data);
    }
    setLoading(false);
  };
  const saveFile = () => {
    // This does the trick!
    pdf(<MyDocument invoice={invoice} />)
      .toBlob()
      .then((blob) => saveAs(blob, `${invoice?.customer?.userName}.pdf`));
  };
  React.useEffect(() => {
    getinvoice();
  }, [params.id, id, startDate, endDate]);

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <button
        onClick={saveFile}
        className="bg-primary w-full py-2 text-center text-white font-medium text-sm h-10"
      >
        Save File
      </button>

      <PDFViewer
        className="
"
        style={{ width: "100%", height: "calc(100vh - 40px)" }}
      >
        <MyDocument invoice={invoice} />
      </PDFViewer>
    </div>
  );
};

export default InvoiceConvertor;
