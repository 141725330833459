import axios from "axios";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoCheckmark, IoChevronDown, IoClose } from "react-icons/io5";
import { URL } from "../../utils/api";

// Custom debounce hook implementation
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
};

const SearchableMultiSelect = ({ value = [], onChange, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const containerRef = useRef(null);
  const debouncedSearch = useDebounce(search, 300);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Fetch customers when search changes
  useEffect(() => {
    const fetchCustomers = async () => {
      if (!debouncedSearch) {
        setCustomers([]);
        return;
      }

      setLoading(true);
      try {
        const response = await axios.post(
          `${URL}/admin/client-customer-search`,
          {
            query: debouncedSearch,
            limit: 10,
          }
        );
        setCustomers(response.data);
      } catch (error) {
        console.error("Error fetching customers:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCustomers();
  }, [debouncedSearch]);

  const handleSelect = (customer) => {
    const newValue = value.some((item) => item._id === customer._id)
      ? value.filter((item) => item._id !== customer._id)
      : [...value, customer];
    onChange(newValue);
  };

  const handleRemove = (e, customerId) => {
    e.stopPropagation();
    onChange(value.filter((item) => item._id !== customerId));
  };

  const selectedCustomers = value;

  return (
    <div className="flex flex-col md:flex-row gap-1 md:gap-3 md:items-center justify-between">
      <label className="text-sm font-medium">Access</label>
      <div className="relative w-full md:w-64" ref={containerRef}>
        <div
          className={classNames(
            "min-h-10 w-full cursor-pointer  border-2 border-black bg-white p-1.5",
            "focus-within:ring-2 focus-within:ring-black focus-within:ring-offset-2",
            isOpen && "ring-2 ring-black ring-offset-2",
            className
          )}
          onClick={() => setIsOpen(true)}
        >
          <div className="flex flex-wrap gap-1">
            {selectedCustomers.map((customer) => (
              <span
                key={customer._id}
                className="inline-flex items-center gap-1 rounded bg-yellow-200 px-2 py-1 text-sm"
              >
                {customer.userName}
                <button
                  onClick={(e) => handleRemove(e, customer._id)}
                  className="rounded-full p-0.5 hover:bg-yellow-300"
                >
                  <IoClose className="w-3 h-3" />
                </button>
              </span>
            ))}
            <input
              type="text"
              className="flex-1 border-none bg-transparent outline-none placeholder:text-gray-400"
              placeholder={value.length === 0 ? "Select Access" : ""}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onFocus={() => setIsOpen(true)}
            />
          </div>
          <button
            type="button"
            className="absolute right-2 top-1/2 -translate-y-1/2"
            onClick={() => setIsOpen(!isOpen)}
          >
            <IoChevronDown
              className={classNames(
                "w-4 h-4 transition-transform",
                isOpen && "rotate-180"
              )}
            />
          </button>
        </div>

        {isOpen && (
          <div className="absolute z-[51] mt-1 max-h-60 w-full overflow-auto rounded border border-gray-200 bg-white shadow-lg">
            {loading ? (
              <div className="flex items-center justify-center p-4">
                <AiOutlineLoading3Quarters className="w-4 h-4 animate-spin" />
              </div>
            ) : customers.length > 0 ? (
              <div className="p-1">
                {customers.map((customer) => (
                  <div
                    key={customer._id}
                    className={classNames(
                      "flex cursor-pointer items-center justify-between rounded px-2 py-1.5",
                      "hover:bg-gray-100",
                      value.some((item) => item._id === customer._id) &&
                        "bg-yellow-100 hover:bg-yellow-200"
                    )}
                    onClick={() => handleSelect(customer)}
                  >
                    <span className="text-sm">{customer.userName}</span>
                    {value.some((item) => item._id === customer._id) && (
                      <IoCheckmark className="w-4 h-4 text-yellow-600" />
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div className="p-2 text-center text-sm text-gray-500">
                {debouncedSearch
                  ? "No customers found"
                  : "Start typing to search"}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchableMultiSelect;
